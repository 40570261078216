

const RoutePath = {
    home: "/",
    //Appointment Paths
    appointmentPage: "/book",
    selectServicePage: "/select-service-page",
    selectBranchPage: "/select-branch-page",
    selectDateAndTimePage: "/select-date-and-time-page",
    verifyContactDetailsPage: "/verify-contact-details-page",
    appointmentCompletedPage: "/appointment-completed-page",
    findYourAppointmentPage: "/find-your-appointment-page",
    cancelAppointmentPage: "/cancel-appointment-page",
    appointmentEditCompletedPage: "/appointment-edit-completed-page",
    cancelAppointmentCompletedPage: "/cancel-appointment-completed-page",
    //Default
    unknownPage: "*"
}

export {
    RoutePath
}