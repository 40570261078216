import { css } from "aphrodite";
import scheduleButtonIcon from "../../../assets/icons/scheduleButtonIcon.svg";
import styles from "./styles";
import { Button, Fade, Typography } from "@mui/material";
import { useState } from "react";

const AnimatedButton = ({
    isSelected = false,
    text = "",
    iconSrc = scheduleButtonIcon,
    onClick = () => {},
}) => {
    const [hovered, setHovered] = useState(false);

    return (
        <Button
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            variant="contained"
            className={css([styles.button, hovered && styles.buttonHovered])}
            disableElevation
            disableRipple
            onClick={onClick}  
            color={isSelected ? "secondary" : "primary"}
        >
            {hovered && (
                <Fade in={hovered} timeout={300}>
                    <Typography variant="body1" className={css(styles.text)}>
                        {text}
                    </Typography>
                </Fade>
            )}
            <img src={iconSrc} alt="icon" className={css(styles.icon)} />
        </Button>
    );
};

export default AnimatedButton;
