import React, { useEffect } from "react";
import { css } from "aphrodite";
import styles from "./styles";
import { Fade } from "@mui/material";
import { useSelector } from "react-redux";
import Appointment from "../../../Models/Appointment/Appointment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import CustomInput from "../../common/components/CustomInput/CustomInput";
import { stepLayoutItemsId, validatePhoneNumber } from "../../../Utils";
import { useTranslation } from "react-i18next";
import { CommonLayout } from "../../common/components/CommonLayout";
import { StepInfo } from "../../common/components/StepInfo";
import { CustomMainButton } from "../../common/components/CustomMainButton";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../Utils/routePath";
import { RequestLayout } from "../../common/components/RequestLayout";
import { Media } from "../../../Models";

const VerifyContactDetailsPage = () => {
    const [t] = useTranslation(["translation"]);
    const navigate = useNavigate();
    const currentStep = stepLayoutItemsId.verifyContactDetails;
    const stepItems = useSelector(Appointment.getStepItems);
    const appointmentState = useSelector(Appointment.getAppointmentState);
    const webView = useSelector(Media.getIsWebView);
    const userInformation = useSelector(Appointment.getUserInformation);
    const bookCode = useSelector(Appointment.getBookCode);
    const loading = useSelector(Appointment.getAppointmentIsLoading);
    const error = useSelector(Appointment.getAppointmentError);

    useEffect(() => {
        if (bookCode) {
            navigate(RoutePath.appointmentCompletedPage);
        }
    }, [bookCode, navigate]);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(t("Name is required")),
        lastName: Yup.string()
            .required(t("Last name is required")),
        tin: Yup.string().required(t("TIN is required")),
        email: Yup.string()
            .email(t("Invalid email"))
            .required(t("Email is required")),
        phoneNumber: Yup.string()
            .test(
                "is-valid-phone-number",
                t("Phone number is not valid"),
                validatePhoneNumber
            )
            .required(t("Phone number is required")),
    });

    const handleBookAppointment = (informationValues) => {
        const appointmentData = {
            Start: appointmentState.selectedAvailableSlot?.start,
            Channel: appointmentState.selectedChannel,
            BranchId: appointmentState.selectedBranch,
            Description: "",
            NewGuests: [
                {
                    firstName: informationValues?.firstName,
                    lastName: informationValues?.lastName,
                    phoneNumber: informationValues?.phoneNumber,
                    email: informationValues?.email,
                },
            ],
        };
        Appointment.requestBookAppointment(
            appointmentState.selectedService,
            appointmentData
        );
    };

    return (
        <CommonLayout>
            <StepInfo
                activeStepId={currentStep}
                layoutItems={stepItems}
                webView={webView}
            />
            <RequestLayout loading={loading} error={error}>
                <div className={css(styles.centerContainer)}>
                    <Formik
                        initialValues={
                            userInformation || {
                                firstName: "",
                                lastName: "",
                                email: "",
                                tin: "",
                                phoneNumber: "",
                            }
                        }
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            Appointment.setUserInformation(values);
                            handleBookAppointment(values);
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                            dirty,
                        }) => (
                            <>
                                <Field
                                    label={t("Name")}
                                    type="text"
                                    name="firstName"
                                    placeholder={t("Name")}
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    component={CustomInput}
                                />
                                <Field
                                    label={t("Last Name")}
                                    type="text"
                                    name="lastName"
                                    placeholder={t("Last Name")}
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    component={CustomInput}
                                />
                                <Field
                                    label={t("TIN")}
                                    type="text"
                                    name="tin"
                                    placeholder={t("TIN")}
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    component={CustomInput}
                                />
                                <Field
                                    label={t("E-mail")}
                                    type="text"
                                    name="email"
                                    placeholder="name@domain.com"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    component={CustomInput}
                                />
                                <Field
                                    label={t("Mobile Phone")}
                                    type="text"
                                    name="phoneNumber"
                                    placeholder={t("+30 XXXXX")}
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    component={CustomInput}
                                />
                                <Fade timeout={500} in={isValid && dirty}>
                                    <div className={css(styles.confirmDiv)}>
                                        <CustomMainButton
                                            fixedSize={false}
                                            value={t("Book Appointment")}
                                            onClick={handleSubmit}
                                            style={styles.button}
                                        />
                                    </div>
                                </Fade>
                            </>
                        )}
                    </Formik>
                </div>
            </RequestLayout>
        </CommonLayout>
    );
};

export { VerifyContactDetailsPage };
