import { css } from "aphrodite";
import styles from "./styles";
import { CustomDatePicker } from "../../../../common/components/CustomDatePicker";
import SlotListPicker from "../../../../common/components/SlotListPicker/SlotListPicker";
import { CustomMainButton } from "../../../../common/components/CustomMainButton";
import { useState } from "react";
import { useNavigate } from "react-router";
import { MobileTitle } from "../../../../common/components/MobileTitle";
import calendarMobileIcon from "../../../../assets/icons/calendarMobileIcon.svg";
import timeMobileIcon from "../../../../assets/icons/timeMobileIcon.svg";
import { useTranslation } from "react-i18next";
import { RequestLayout } from "../../../../common/components/RequestLayout";
import { CommonLayout } from "../../../../common/components/CommonLayout";

const DateAndTimeMobileView = ({
    error,
    loading,
    slotItems,
    onDateChange,
    selectAvailableSlot,
    onSlotClick,
    handleContinue,
    appointmentDataChanged,
}) => {
    const [selectDate, setSelectDate] = useState(true);
    const navigate = useNavigate();
    const [t] = useTranslation(["translation"]);

    const handleContinueButtonPressed = () => {
        if (selectDate) {
            setSelectDate(false);
        } else {
            handleContinue();
        }
    };

    const handleGoBackButtonPressed = () => {
        if (selectDate) {
            navigate(-1);
        } else {
            setSelectDate(true);
        }
    };
    return (
        <CommonLayout>
            <MobileTitle
                onClickBackButton={handleGoBackButtonPressed}
                iconSrc={selectDate ? calendarMobileIcon : timeMobileIcon}
                title={selectDate ? t("Select Date") : t("Select Time")}
            />
            <RequestLayout error={error} loading={loading}>
                <div className={css(styles.container)}>
                    <div className={css(styles.mainDiv)}>
                        {selectDate ? (
                            <CustomDatePicker
                                title=""
                                onDateChange={onDateChange}
                            />
                        ) : (
                            <SlotListPicker
                                title=""
                                slotItems={slotItems}
                                onSlotClick={onSlotClick}
                                activeSlot={selectAvailableSlot}
                            />
                        )}
                        <CustomMainButton
                            isDisabled={appointmentDataChanged}
                            value={t("Continue")}
                            fixedSize={false}
                            textVariant={"body1"}
                            style={styles.customButton}
                            onClick={handleContinueButtonPressed}
                        />
                    </div>
                </div>
            </RequestLayout>
        </CommonLayout>
    );
};

export default DateAndTimeMobileView;
