import { StyleSheet } from "aphrodite";
import theme from "../../../common/theme/mellon";

export default StyleSheet.create({
    datePickerContainer: {
        borderBottom: "0.6px solid" + theme.palette.primary.dark,
        borderTop: "0.6px solid" + theme.palette.primary.dark,
        height: "360px",
        width: "100%",
        '@media (max-width: 799px)': {
            border: "0px"
        }
    },
    buttonStyle: {
        marginLeft: "20px",
        marginBottom: "20px",
    },
    popup: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        color: theme.palette.primary.main,
        maxWidth: "110px",
        textAlign: "center",
    },
    description: {
        color: theme.palette.secondary.main,
        margin: "5px",
        maxWidth: "110px",
        textAlign: "center",
    },
    popupButton: {
        marginTop: "5px",
        color: theme.colors.white,
        background: theme.palette.primary.main,
        padding: "0px 15px",
        borderRadius: "5px",
        maxWidth: "110px",
        width: "100%",
        textTransform: "none",
    },
    newButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.primary.main,
        color: theme.colors.white,
        borderRadius: "5px",
        padding: "0px",
        height: "30px",
        width: "30px",
    },
});
