import { StyleSheet } from "aphrodite";
import theme from "../../../common/theme/mellon";

export default StyleSheet.create({
    button: {
        width: 250,
        height: 250, 
        background: theme.palette.primary.main,
        borderRadius : 0,
        '@media (max-width: 799px)' : {
            width: 190,
            height: 190
        }
    },
});