import "./App.css";
import theme from "./View/common/theme/mellon";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { DefaultPage } from "./View/pages/DefaultPage";
import { StylesProvider } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AppointmentPage } from "./View/pages/AppointmentPage";
import { SelectServicePage } from "./View/pages/SelectServicePage";
import { RoutePath } from "./Utils/routePath";
import { SelectBranchPage } from "./View/pages/SelectBranchPage";
import { DateAndTimePage } from "./View/pages/DateAndTimePage";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { debounce } from "@mui/material";
import Media from "./Models/Media/Media";
import { Layout } from "./View/layout";
import { FindYourAppointmentPage } from "./View/pages/FindYourAppointmentPage";
import { VerifyContactDetailsPage } from "./View/pages/VerifyContactDetailsPage/VerifyContactDetailsPage";
import { ToastContainer } from "react-toastify";
import { CancelAppointmentPage } from "./View/pages/CancelAppointmentPage";
import { CancelAppointmentCompletedPage } from "./View/pages/CancelAppointmentCompletedPage";
import { AppointmentCompletedPage } from "./View/pages/AppointmentCompletedPage";
import { AppointmentEditCompletedPage } from "./View/pages/AppointmentEditCompletedPage";
import { Appointment } from "./Models";

const App = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const appointmentRedirectRoutes = useSelector(Appointment.getAppointmentRedirectRoutes);
    const webView = useSelector(Media.getIsWebView);
    const MOBILE_BREAKPOINT = 800;
 
    useEffect(() => {
        if (appointmentRedirectRoutes?.includes(location.pathname)) {
            navigation(RoutePath.appointmentPage); 
        } 
    }, [
        location,
        appointmentRedirectRoutes,
        navigation,
    ]);

    useEffect(() => {
        console.log(process.env.REACT_APP_VERSION);
        const handleResize = debounce(() => {
            if (window.innerWidth < MOBILE_BREAKPOINT) {
                Media.setWebView(false);
            } else {
                Media.setWebView(true);
            }
        }, 100);

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <ToastContainer />
                <CssBaseline />
                <Layout webView={webView}>
                    <Routes
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        {/* Appointment Paths */}
                        <Route path={RoutePath.appointmentPage} element={<AppointmentPage/>} />
                        <Route path={RoutePath.selectServicePage} element={<SelectServicePage/>} />
                        <Route path={RoutePath.selectBranchPage} element={<SelectBranchPage/>} />
                        <Route path={RoutePath.selectDateAndTimePage} element={<DateAndTimePage/>} />
                        <Route path={RoutePath.verifyContactDetailsPage} element={<VerifyContactDetailsPage/>} />
                        <Route path={RoutePath.appointmentCompletedPage} element={<AppointmentCompletedPage />} />
                        <Route path={RoutePath.findYourAppointmentPage} element={<FindYourAppointmentPage />} />
                        <Route path={RoutePath.cancelAppointmentPage} element={<CancelAppointmentPage />} />
                        <Route path={RoutePath.appointmentEditCompletedPage} element={<AppointmentEditCompletedPage/>} />
                        <Route path={RoutePath.cancelAppointmentCompletedPage} element={<CancelAppointmentCompletedPage />} />
                        {/* Default */}
                        <Route path={RoutePath.home} element={<Navigate to={RoutePath.appointmentPage}/>} /> 
                        <Route path={RoutePath.unknownPage} element={<DefaultPage/>} />
                    </Routes>
                </Layout>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default App;
