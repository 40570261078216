import { StyleSheet } from "aphrodite";
import theme from "../../common/theme/mellon";

export default StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    mainContainer: {
        flex: 1,
        display: "flex",
        paddingTop: "40px",
    },
    flexContainer: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px",
    },
    confirmDiv: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "30px",
        marginBottom: "15px",
    },
    centerContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    row: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "center",
    },
    halfColumn: {
        flex: 1,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    space: {
        marginRight: "15px",
    },
    notesDiv: {
        maxWidth: "564px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    fieldDiv: {
        width: "100%",
        background: theme.colors.white,
        border: "1px solid " + theme.palette.secondary.dark,
        padding: "5px 15px",
        borderRadius: "5px",
    },
    mobileDiv: {
        marginTop: "15px",
        gap: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: "320px",
    },
    button: {
        width: 400,
        height: 60,
        alignSelf: "flex-end",
        "@media (max-width: 799px)": {
            width: "100%",
            maxWidth: "400px",
            alignSelf: "center",
        },
    },
});
