import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    stickyTop: {
        position: "sticky",
        top: 0,
        width: "100%",
        zIndex: 1000,
    },
    stickyBottom: {
        position: "sticky",
        bottom: 0,
        width: "100%",
        zIndex: 1000,
    },
    children: {
        flex: 1,
        overflowY: "auto",
    },
});
