import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { css } from "aphrodite";
import styles from "./styles";
import { notify } from "../../../../Utils";

const RequestLayout = ({
    children,
    error,
    loading,
    overlayLoading = false,
}) => {

    useEffect(() => {
        if (error) {
            notify(error, "error", 4000);
        }
    }, [error]);

    return (
        <>
            <>
                {loading && !overlayLoading ? (
                    <div
                        className={css(
                            styles.centerContainer,
                            styles.smallPadding
                        )}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {children}
                        <Backdrop
                            className={css(styles.backDrop)}
                            open={Boolean(loading && overlayLoading)}
                        >
                            <CircularProgress />
                        </Backdrop>
                    </>
                )}
            </>
        </>
    );
};

export default RequestLayout;
