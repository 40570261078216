import { css } from "aphrodite";
import { CommonLayout } from "../../common/components/CommonLayout";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import cancelAppointmentIcon from "../../assets/icons/cancelAppointmentIcon.svg";
import { CommonLayoutTitle } from "../../common/components/CommonLayoutTitle";
import { RequestLayout } from "../../common/components/RequestLayout";
import { CustomMainButton } from "../../common/components/CustomMainButton";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../Utils/routePath";
import { useSelector } from "react-redux";
import { Appointment, Media } from "../../../Models";
import { useEffect } from "react";
import { CompleteCommonMobileLayout } from "../../common/components/CompleteCommonMobileLayout";
import { MobileTitle } from "../../common/components/MobileTitle";
import cancelAppointmentMobile from "../../assets/icons/cancelAppointmentMobileIcon.svg";

const CancelAppointmentPage = () => {
    const [t] = useTranslation(["translation"]);
    const navigate = useNavigate();
    const cancelLoading = useSelector(Appointment.getCancelAppointmentLoading);
    const cancelError = useSelector(Appointment.getCancelAppointmentError);
    const appointmentInfoData = useSelector(Appointment.getAppointmentInfoData);
    const cancelData = useSelector(Appointment.getCancelAppointmentData);
    const webView = useSelector(Media.getIsWebView);

    useEffect(() => {
        if (cancelData) {
            navigate(RoutePath.cancelAppointmentCompletedPage);
        }
    }, [cancelData, navigate]);

    const handleCancelAppointment = () => {
        if (appointmentInfoData?.id) {
            Appointment.requestCancelAppointment(appointmentInfoData.id);
        }
    };

    const handleGoHome = () => {
        navigate(RoutePath.appointmentPage);
    };

    const handleGoBack = () => {
        navigate(-1);
    }

    return webView ? (
        <CommonLayout>
            <CommonLayoutTitle
                statusTitle={t("Edit Appointment")}
                statusSubtitle={t("Cancel Appointment")}
                iconSrc={cancelAppointmentIcon}
                title={t("Cancel Appointment")}
                description={t(
                    "Are you certain you wish to cancel your appointment?"
                )}
                stepDataLayoutVisible={true}
            />
            <RequestLayout loading={cancelLoading} error={cancelError}>
                <div className={css(styles.container)}>
                    <CustomMainButton
                        color="error"
                        style={styles.button}
                        value={t("Cancel Appointment")}
                        onClick={handleCancelAppointment}
                    />
                    <CustomMainButton
                        color="primary"
                        style={styles.button}
                        value={t("Back to Home")}
                        onClick={handleGoHome}
                    />
                </div>
            </RequestLayout>
        </CommonLayout>
    ) : (
        <CommonLayout>
            <RequestLayout loading={cancelLoading} error={cancelError}>
                <MobileTitle iconSrc={cancelAppointmentMobile} title={t("Cancel Appointment")} onClickBackButton={handleGoBack}/>
                <CompleteCommonMobileLayout
                    description={t(
                       "Are you certain you wish to cancel your appointment?"
                    )}
                    appointmentValuesVisible={true}
                    handleGoHome={handleGoHome}
                    secondaryButtonColor="error"
                    secondaryButtonOnClick={handleCancelAppointment}
                    secondaryButtonTitle={t("Cancel Appointment")}
                    secondaryButtonVisible={true}
                />
            </RequestLayout>
        </CommonLayout>
    );
};

export default CancelAppointmentPage;
