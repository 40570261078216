import { StyleSheet } from "aphrodite";


export default StyleSheet.create({
    container: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    customButton: {
        width: "100%",
        marginTop: "15px",
        marginBottom: "15px",
        height: 60
    },
    mainDiv: {
        width: "100%",
        maxWidth: 320,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-start"
    }
})