import {StyleSheet} from 'aphrodite';

export default StyleSheet.create({
    container: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 50
    },
    customButton: {
        width: "100%",
        marginTop: "15px",
        marginBottom: "15px",
    }
});
