import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Appointment from "../../../Models/Appointment/Appointment";
import { RequestLayout } from "../../common/components/RequestLayout";
import { getNextStepPath, stepLayoutItemsId } from "../../../Utils";
import Media from "../../../Models/Media/Media";
import { SelectBranchComponent } from "../../common/components/SelectBranchComponent";
import { css } from "aphrodite";
import styles from "./styles";
import { StepInfo } from "../../common/components/StepInfo";
import { CommonLayout } from "../../common/components/CommonLayout";

const SelectBranchPage = () => {
    const navigate = useNavigate();
    const currentStep = stepLayoutItemsId.selectBranch;
    const serviceId = useSelector(Appointment.getSelectedService);
    const isLoading = useSelector(Appointment.getBranchesLoading);
    const branchesData = useSelector(Appointment.getBranchesData);
    const selectedBranch = useSelector(Appointment.getSelectedBranch);
    const error = useSelector(Appointment.getBranchesError);
    const webView = useSelector(Media.getIsWebView);
    const stepItems = useSelector(Appointment.getStepItems);
    const isAppointmentByBranch = useSelector(Appointment.getIsAppointmentByBranch);

    useEffect(() => {
        if (serviceId && !isAppointmentByBranch) {
            Appointment.fetchBranches(serviceId);
        } else if (isAppointmentByBranch) {
            Appointment.fetchBranches();
        }
    }, [serviceId, isAppointmentByBranch]);

    const handleOnBranchSelect = (id, name) => {
        Appointment.setSelectedBranch(id, name);
        Appointment.stepCompleted(currentStep);
        navigate(getNextStepPath(currentStep, stepItems));
    }

    return (
        <div className={css(styles.container)}>
            <CommonLayout mainLayout={false}>
                <StepInfo activeStepId={currentStep} layoutItems={stepItems} webView={webView} />
            </CommonLayout>
            <RequestLayout loading={isLoading} error={error}>
                {branchesData && (
                    <SelectBranchComponent
                        selectedBranchId={selectedBranch}
                        webView={webView}
                        branches={branchesData.map((item) => ({
                            id: item?.id,
                            name: item?.name,
                            address: item?.address,
                            phoneNumber: item?.phoneNumber,
                            email: item?.email,
                            latitude: item?.latitude,
                            longitude: item?.longitude,
                        }))}
                        onBranchSelect={handleOnBranchSelect}
                    />
                )}
            </RequestLayout>
        </div>
    );
};

const memoizedSelectBranchPage = React.memo(SelectBranchPage);
export { memoizedSelectBranchPage as SelectBranchPage };
