import { getAppBarUtilityClass } from "@mui/material";
import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "100%"
    },
    title: {
        margin: "14px 0px 25px 0px"
    },
    alignStart: {
        alignSelf: "flex-start"
    },
    subTitle: {
        marginBottom: "10px"
    },
    description: {
        padding: "30px 0px",
    },
    referenceNumberTitle: {
        marginTop: "30px"
    },
    mobileButton: {
        width: "100%",
        height: "60px",
        maxWidth: "400px",
        marginBottom: 10
    },
    gap: {
        marginBottom: 20
    }
});