import React, { useEffect, useState } from "react";
import { css } from "aphrodite";
import styles from "./styles";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Appointment from "../../../Models/Appointment/Appointment";
import { RequestLayout } from "../../common/components/RequestLayout";
import { CustomDatePicker } from "../../common/components/CustomDatePicker";
import SlotListPicker from "../../common/components/SlotListPicker/SlotListPicker";
import {
    calculateEndTime,
    getNextStepPath,
    isDateGreaterThan,
    isSameDate,
    stepLayoutItemsId,
} from "../../../Utils";
import { CommonLayout } from "../../common/components/CommonLayout";
import { StepInfo } from "../../common/components/StepInfo";
import CustomMainButton from "../../common/components/CustomMainButton/CustomMainButton";
import { RoutePath } from "../../../Utils/routePath";
import { Media } from "../../../Models";
import DateAndTimeMobileView from "./components/DateAndTimeMobileView/DateAndTimeMobileView";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const DateAndTimePage = () => {
    const navigate = useNavigate();
    const currentStep = stepLayoutItemsId.selectDateAndTime;
    const stepItems = useSelector(Appointment.getStepItems);
    const serviceId = useSelector(Appointment.getSelectedService);
    const selectedBranch = useSelector(Appointment.getSelectedBranch);
    const availabilityData = useSelector(Appointment.getAvailabilityData);
    const selectedChannel = useSelector(Appointment.getSelectedChannel);
    const selectedEventTypeId = useSelector(Appointment.getSelectedEventTypeId);
    const loading = useSelector(Appointment.getAvailabilityLoading);
    const error = useSelector(Appointment.getAvailabilityError);
    const selectAvailableSlot = useSelector(
        Appointment.getSelectedAvailableSlot
    );
    const isEditAppointmentMode = useSelector(
        Appointment.getIsEditAppointmentMode
    );
    const appointmentDataChanged = useSelector(
        Appointment.getAppointmentDataChanged
    );
    const appointmentEditData = useSelector(Appointment.getAppointmentEditData);
    const appointmentEditIsLoading = useSelector(
        Appointment.getAppointmentEditLoading
    );
    const appointmentEditError = useSelector(
        Appointment.getAppointmentEditError
    );
    const appointmentInfoData = useSelector(Appointment.getAppointmentInfoData);
    const selectedServiceName = useSelector(Appointment.getSelectedServiceName);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const webView = useSelector(Media.getIsWebView);
    const [t] = useTranslation(["translation"]);
    const currentDate = new Date();

    useEffect(() => {
        const startDay = new Date();
        startDay.setHours(0, 0, 0, 0);
        const endDay = new Date(startDay);
        endDay.setDate(endDay.getDate() + 15);
        if (serviceId && selectedBranch) {
            const branch = selectedBranch;
            Appointment.fetchAvailability(
                serviceId,
                branch,
                startDay.toISOString(),
                endDay.toISOString()
            );
        }
    }, [serviceId, selectedBranch]);

    useEffect(() => {
        if (appointmentEditData?.id) {
            navigate(RoutePath.appointmentEditCompletedPage);
        }
    }, [appointmentEditData, navigate]);

    const handleContinue = () => {
        if (isEditAppointmentMode) {
            if (selectAvailableSlot) {
                Appointment.editAppointment(appointmentInfoData?.id, {
                    id: appointmentInfoData?.id,
                    branchId: selectedBranch,
                    services: [serviceId],
                    start: selectAvailableSlot.start,
                    channelId: selectedChannel,
                    typeId: selectedEventTypeId,
                    title:
                        appointmentInfoData?.title ||
                        appointmentEditData?.name +
                            " for " +
                            selectedServiceName,
                    end:
                        selectAvailableSlot?.end ||
                        calculateEndTime(
                            selectAvailableSlot.start,
                            selectAvailableSlot.duration
                        ),
                    guests: appointmentInfoData?.guests || [],
                    users: appointmentInfoData?.users || [],
                });
            }
        } else {
            if (selectAvailableSlot) {
                Appointment.visibilityForSelectedSlotData(true);
                Appointment.stepCompleted(currentStep);
                navigate(getNextStepPath(currentStep, stepItems));
            }
        }
    };

    const handleOnDateChange = (newSelectedDate) => {
        setSelectedDate(newSelectedDate);
    };

    const handleSlotClick = (slot) => {
        Appointment.setSelectedAvailableSlot(slot);
    };

    return webView ? (
        <CommonLayout>
            <StepInfo activeStepId={currentStep} layoutItems={stepItems} />
            <RequestLayout
                error={error || appointmentEditError}
                loading={loading || appointmentEditIsLoading}
            >
                {availabilityData && (
                    <div className={css(styles.container)}>
                        <CustomDatePicker onDateChange={handleOnDateChange} title={t("Select Date")}/>
                        <Box sx={{ width: "100%", maxWidth: 445 }}>
                            <SlotListPicker
                                title={t("Select Time")}
                                slotItems={availabilityData.slots.filter(
                                    (item) =>
                                        isSameDate(item.start, selectedDate) &&
                                        isDateGreaterThan(
                                            item.start,
                                            currentDate
                                        )
                                )}
                                onSlotClick={handleSlotClick}
                                activeSlot={selectAvailableSlot}
                            />
                            <CustomMainButton
                                isDisabled={appointmentDataChanged}
                                value={t("Continue")}
                                fixedSize={false}
                                textVariant={"body1"}
                                style={styles.customButton}
                                onClick={handleContinue}
                            />
                        </Box>
                    </div>
                )}
            </RequestLayout>
        </CommonLayout>
    ) : (
        <DateAndTimeMobileView
            error={error || appointmentEditError}
            loading={loading || appointmentEditIsLoading}
            appointmentDataChanged={appointmentDataChanged}
            handleContinue={handleContinue}
            onSlotClick={handleSlotClick}
            selectAvailableSlot={selectAvailableSlot}
            slotItems={
                availabilityData
                    ? availabilityData?.slots?.filter(
                          (item) =>
                              isSameDate(item.start, selectedDate) &&
                              isDateGreaterThan(item.start, currentDate)
                      )
                    : []
            }
            onDateChange={handleOnDateChange}
        />
    );
};

export { DateAndTimePage };
