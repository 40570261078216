import { StyleSheet } from "aphrodite";
import theme from "../../common/theme/mellon";

export default StyleSheet.create({
    titleRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        gap: 24,
        color: theme.palette.primary.dark,
        margin: "25px 0px",
        "@media (max-width: 799px)": {
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0px",
            gap: 10
        }
    },
    cancelButton: {
        width: "100%",
        height: "60px",
        maxWidth: "400px",
        marginBottom: "20px",
        "@media (max-width: 799px)": {
            alignSelf: "center"
        }
    },
    gridDiv: {
        paddingBottom: "15px",
        '@media (max-width: 927px)': {
            justifyContent: "center",
            alignItems: "center",
        },
    },
    insideGrid: {
        '@media (max-width: 927px)': {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
        }
    },
    bottomPadding: {
        paddingBottom: 180,
        '@media (max-width: 927px)': {
            paddingBottom: 150,
        }
    }
});
