import { css } from "aphrodite";
import styles from "./styles";
import { IconButton, Typography } from "@mui/material";
import backButtonIcon from "../../../assets/icons/backButtonIcon.svg";


const MobileTitle = ({
    iconSrc,
    title,
    onClickBackButton = () => {}
}
) => {

    return (
        <div className={css(styles.container)}>
            <div className={css(styles.buttonDiv)}>
                <IconButton 
                    onClick={onClickBackButton}
                    className={css(styles.button)}
                >
                    <img alt="" src={backButtonIcon} />
                </IconButton>
            </div>
            <div className={css(styles.titleDiv)}>
                <img alt="" src={iconSrc} width={26} height={26}/>
                <Typography variant="h3">
                    {title}
                </Typography>
            </div>

        </div>
    )
}

export default MobileTitle;