import { IconButton } from "@mui/material"
import styles from "./styles"
import { css } from "aphrodite";


const CustomRectangleIconButton = ({
    iconSrc,
    onClick = () => {}
}) => {
    return (
        <IconButton 
            onClick={onClick}
            className={css(styles.button)}
            variant="contained"
            color="primary"
        >
            <img src={iconSrc} alt="" />
        </IconButton>
    )
}

export default CustomRectangleIconButton