// import createAction from './createAction';

import Logger from "./Logger";

const simpleAction = type => {
  return { type };
};
const request = (type, request, params) => {
  return { type, request, params };
};
const success = (type, response) => {
  return { type, response };
};
const failure = (type, error) => {
  return { type, error };
};

const createAction = {
  request,
  success,
  failure,
  simpleAction,
};

const dispatchRequest = (type, request, params = { withoutRequest: false, subtype: '' }) => {
  const log = new Logger();
  return dispatch => {
    return new Promise((resolve, reject) => { 
      if (!params.withoutRequest) {
        dispatch(createAction.request(type.REQUEST + params.subtype, request, params));
      }
      request(params).then(
        response => {
          if (response?.status === "error") { 
            dispatch(createAction.failure(type.FAILURE + params.subtype, response));
            reject(response);
          } else {
            dispatch(createAction.success(type.SUCCESS + params.subtype, response));
            resolve(response);
          }
        },
        error => {
          dispatch(createAction.failure(type.FAILURE + params.subtype, error.toString()));
          log.error("dispatchRequest@error", error.toString())
          reject(error);
        },
      );
    });
  };
};

const dispatchCustomAction = (type, payload) => {
  return dispatch => {
    dispatch({type, payload});
  };
};

const dispatchHelper = {
  dispatchRequest,
  dispatchCustomAction,
};

export default dispatchHelper;
