import { StyleSheet } from "aphrodite"
import theme from "../../../common/theme/mellon"
export default StyleSheet.create({
    container: {
        margin: "33px 0px 26px 0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    titleRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        gap: 24,
        color: theme.palette.primary.dark,
    },
    stepsContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        flexWrap: "wrap", 
        gap: 8, 
    },
    stepsRow: {
        color: theme.palette.secondary.main,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
});