import {StyleSheet} from 'aphrodite';

export default StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
    flexGrow: 1,
  }
});
