import { css } from "aphrodite";
import styles from "./styles";
import { Grid2, Typography } from "@mui/material";
import { getTimeFromISOString } from "../../../../Utils";
import { useTranslation } from "react-i18next";
import { SlotButton } from "../SlotButton";

const SlotListPicker = ({
    activeSlot,
    title = "Select Slot",
    slotItems = [],
    onSlotClick = (slot) => {},
}) => {
    const [t] = useTranslation(["translation"]);
    const activeSlots =
        !slotItems || slotItems.length === 0
            ? []
            : slotItems.filter((item) => item?.availability !== 0);
    let displayedItems = activeSlots.length === 0 ? [] : activeSlots;

    return (
        <div className={css(styles.layout)}>
            {title && (
                <Typography color="inherit" variant="h6">
                    {title}
                </Typography>
            )}
            <div className={css(styles.container)}>
                {activeSlots.length === 0 ? (
                    <Typography
                        className={css(styles.noAvailability)}
                        variant="h6"
                    >
                        {t("No Availability.")}
                    </Typography>
                ) : (
                    <>
                        <Grid2
                            container
                            spacing={1}
                            sx={{
                                padding: "15px 24px",
                            }}
                        >
                            {displayedItems.map((item, index) => {
                                return (
                                    <Grid2
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            width: "100%",
                                            maxWidth: "71px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <SlotButton
                                            isDisabled={
                                                item?.availability === 0
                                            }
                                            isSelected={
                                                activeSlot?.start === item.start
                                            }
                                            value={getTimeFromISOString(
                                                item.start
                                            )}
                                            onClick={() => {
                                                onSlotClick(item);
                                            }}
                                        />
                                    </Grid2>
                                );
                            })}
                        </Grid2>
                    </>
                )}
            </div>
        </div>
    );
};

export default SlotListPicker;
