import React, { useEffect, useState } from "react";
import { Box, Fade, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Appointment from "../../../Models/Appointment/Appointment";
import { CustomMainButton } from "../../common/components/CustomMainButton";
import { RequestLayout } from "../../common/components/RequestLayout";
import { description, getNextStepPath, stepLayoutItemsId } from "../../../Utils";
import { StepInfo } from "../../common/components/StepInfo";
import { CommonLayout } from "../../common/components/CommonLayout";
import { useTranslation } from "react-i18next";
import { css } from "aphrodite";
import styles from "./styles";
import cancelAppointmentIcon from "../../assets/icons/cancelAppointmentIcon.svg";
import { RoutePath } from "../../../Utils/routePath";
import { Media } from "../../../Models";
import { CustomMobileServiceButton } from "../../common/components/CustomMobileServiceButton";
import cancelAppointmentMobileIcon from "../../assets/icons/cancelAppointmentMobileIcon.svg";
import theme from "../../common/theme/mellon";


const SelectServicePage = () => {
    const navigate = useNavigate();
    const [t] = useTranslation(["translation"]);
    const stepItems = useSelector(Appointment.getStepItems);
    const isLoading = useSelector(Appointment.getServicesLoading);
    const servicesData = useSelector(Appointment.getServicesData);
    const error = useSelector(Appointment.getServicesError);
    const selectedService = useSelector(Appointment.getSelectedService);
    const selectedBranch = useSelector(Appointment.getSelectedBranch);
    const editAppointmentMode = useSelector(Appointment.getIsEditAppointmentMode);
    const waysOfActionIsLoading = useSelector(Appointment.getWaysOfActionLoading);
    const waysOfActionError = useSelector(Appointment.getWaysOfActionError);
    const waysOfActionData = useSelector(Appointment.getWaysOfActionData);
    const currentStep = stepLayoutItemsId.selectService;
    const webView = useSelector(Media.getIsWebView);
    const [infoIdVisible, setInfoIdVisible] = useState(""); 
    const [triggerGoToNextStep, setTriggerGoToNextStep] = useState(false);

    useEffect(() => {
        if (editAppointmentMode) {
            Appointment.fetchServices({
                BranchId: selectedBranch
            })
        } else {
            Appointment.fetchServices();
        }
    }, [editAppointmentMode, selectedBranch]);

    useEffect(() => {
        if (triggerGoToNextStep) {
            if (waysOfActionData?.availableChannels) {
                Appointment.setSelectedChannel(waysOfActionData?.availableChannels[0]); 
                Appointment.stepCompleted(currentStep);
                navigate(getNextStepPath(currentStep, stepItems)); 
                setTriggerGoToNextStep(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerGoToNextStep, waysOfActionData]);

    const handleOnClickButton = (serviceId, eventTypeId, serviceName) => {
        Appointment.setSelectedService(serviceId, eventTypeId, serviceName);
        Appointment.fetchWaysOfAction(eventTypeId);
        setTriggerGoToNextStep(true)
    };

    const handleCancelAppointment = () => { 
        navigate(RoutePath.cancelAppointmentPage);
    }

    const handleInfoClick = (id) => {
        setInfoIdVisible(id);
    }

    return (
        <>
            <CommonLayout mainLayout={false}>
                <StepInfo activeStepId={currentStep} layoutItems={stepItems} webView={webView}/>
            </CommonLayout>
            <RequestLayout loading={isLoading || waysOfActionIsLoading ||  (triggerGoToNextStep && !waysOfActionError)} error={error || waysOfActionError}>
                {servicesData && (
                    <>
                        <CommonLayout mainLayout={false}>
                            <Box sx={{ flexGrow: 1}} className={css(editAppointmentMode && styles.bottomPadding)}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={css(styles.gridDiv)}
                                >
                                    {servicesData.map((item, index) => {
                                        return (
                                            <Grid
                                                className={css(styles.insideGrid)}
                                                key={index}
                                            >
                                                {
                                                    webView ? (
                                                        <CustomMainButton
                                                            isSelected={
                                                                item.id ===
                                                                selectedService
                                                            }
                                                            toolTipValue={
                                                                description[
                                                                    item?.name
                                                                ] || null
                                                            }
                                                            value={item?.name || "-"}
                                                            onClick={() => {
                                                                handleOnClickButton(
                                                                    item.id,
                                                                    item.eventTypeId,
                                                                    item.name
                                                                );
                                                            }}
                                                        />
                                                    ) : (
                                                        <CustomMobileServiceButton 
                                                            isSelected={
                                                                item.id ===
                                                                selectedService
                                                            }
                                                            extraValue={
                                                                description[
                                                                    item?.name
                                                                ] || item?.name
                                                            }
                                                            value={item?.name || "-"}
                                                            onClickButton={() => {
                                                                handleOnClickButton(
                                                                    item.id,
                                                                    item.eventTypeId,
                                                                    item.name
                                                                );
                                                            }}
                                                            extraValueVisible={infoIdVisible === item.id}
                                                            onClickInfo={() => handleInfoClick(item.id)}
                                                        />
                                                    )
                                                }
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </CommonLayout>
                        <Fade appear={false} in={editAppointmentMode}>
                            <Paper
                                role="dialog"
                                square
                                variant="outlined"
                                sx={{
                                    position: "fixed",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    borderWidth: 0,
                                    borderTop: !webView ? `1px solid ${theme.palette.primary.dark}` : "0px"
                                }}
                            >
                                <CommonLayout mainLayout={false}>
                                    <div className={css(styles.titleRow)}>
                                        <img alt="" src={webView ? cancelAppointmentIcon : cancelAppointmentMobileIcon} />
                                        <Typography variant="h4">{t("Cancel Appointment")}</Typography>
                                    </div>
                                    <CustomMainButton
                                        fixedSize={false}
                                        color="error"
                                        style={styles.cancelButton}
                                        value={t("Cancel Appointment")}
                                        onClick={handleCancelAppointment}
                                    />
                                </CommonLayout>
                            </Paper>
                        </Fade>
                    </>
                )}
            </RequestLayout>
        </>
    );
};

export { SelectServicePage };
