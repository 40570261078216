import { css } from "aphrodite";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import StepDataInfo from "../StepDataInfo/StepDataInfo";
import { MobileTitle } from "../MobileTitle";
import { RoutePath } from "../../../../Utils/routePath";

const StepInfo = ({
    activeStepId,
    layoutItems,
    customStatus,
    webView = true,
}) => {
    const [t] = useTranslation(["translation"]);
    const navigate = useNavigate();
    const activeStep = layoutItems.find((item) => item.id === activeStepId);

    const handleNavigation = (stepItem) => {
        navigate(stepItem.path);
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleGoHome = () => {
        navigate(RoutePath.appointmentPage);
    };

    return webView ? (
        <div className={css(styles.container)}>
            {/* Steps or Status */}
            {customStatus ? (
                <Typography variant="h5">{t(customStatus)}</Typography>
            ) : (
                <div className={css(styles.stepsContainer)}>
                    {activeStep?.editMode ? (
                        <Button
                            variant="text"
                            color={"primary"}
                            onClick={handleGoHome}
                            disableElevation
                            disableRipple
                            style={{
                                padding: 0,
                                textTransform: "none",
                                minWidth: "auto",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <Typography variant="h5" color="primary">
                                {t("Edit Appointment")}
                            </Typography>
                        </Button>
                    ) : (
                        <Button
                            variant="text"
                            color={"primary"}
                            onClick={handleGoHome}
                            disableElevation
                            disableRipple
                            style={{
                                padding: 0,
                                textTransform: "none",
                                minWidth: "auto",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <Typography variant="h5" color="primary">
                                {t("New Appointment")}
                            </Typography>
                        </Button>
                    )}
                    {layoutItems
                        .slice(
                            0,
                            layoutItems.findIndex(
                                (item) => item.id === activeStep?.id
                            ) + 1
                        )
                        .map((item, index) => (
                            <div key={item.id} className={css(styles.stepsRow)}>
                                <Typography variant="h5">
                                    &nbsp;/&nbsp;
                                </Typography>
                                <Button
                                    variant="text"
                                    color={
                                        item.id !== activeStep.id
                                            ? "primary"
                                            : "inherit"
                                    }
                                    onClick={() => {
                                        handleNavigation(item);
                                    }}
                                    disableElevation
                                    disableRipple
                                    style={{
                                        padding: 0,
                                        textTransform: "none",
                                        minWidth: "auto",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <Typography variant="h5">
                                        {t(item.value)}
                                    </Typography>
                                </Button>
                            </div>
                        ))}
                </div>
            )}
            <StepDataInfo />
            {/* Title of Page */}
            {activeStep && (
                <div className={css(styles.titleRow)}>
                    <img alt="" src={activeStep.icon} />
                    <Typography variant="h4">{t(activeStep.value)}</Typography>
                </div>
            )}
        </div>
    ) : (
        <MobileTitle
            iconSrc={activeStep.icon}
            title={t(activeStep.mobileValue)}
            onClickBackButton={handleGoBack}
        />
    );
};

export default StepInfo;
