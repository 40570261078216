import i18n from "i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import resources from "./View/common/locales";

const availableLanguages = ["en", "gr"];

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        lng: "en",
        debug: true,
        whitelist: availableLanguages,
        initImmediate: false,
        resources,
        nonExplicitWhitelist: true,
        backend: {
            loadPath: "/src/View/common/locales/{{lng}}/{{ns}}.json",
        },
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
            useSuspense: false,
            bindI18n: "languageChanged loaded",
        },
        returnObjects: true,
    });

export default i18n;
