import { createReducer } from "../../../Utils";
import * as type from "./types";

const initialState = {
    webView: true,
};

export default createReducer(initialState, {
    [type.SET_WEB_VIEW](state, action) {
        return {
            ...state,
            webView: action.payload,
        };
    },
});
