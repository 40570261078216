import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "20px",
        width: "100%"
    },
    button: {
        width: "100%",
        maxWidth: "400px",
        height: "60px",
        marginBottom: "20px",
    }
})