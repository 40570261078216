import {StyleSheet} from 'aphrodite';

export default StyleSheet.create({
    container: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        justifyContent: "center",
        margin: "0 auto",
        paddingBottom: "15px",
        marginTop: "20vh",
        gap: 100,
        '@media (max-width: 799px)': {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "10vh",
            gap: 29,
            paddingBottom: "15px"
        },
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 14
    },
    extraButton: {
        width: "100%",
        display: "flex",
        color: "white",
        padding: "20px 2px",
        '@media (max-width: 799px)': {
            padding: "15px 2px"
        }
    },
    buttonsColumn: {
        width: "100%",
        maxWidth: 250,
        paddingBottom: 14,
        '@media (max-width: 799px)': {
            paddingBottom: 0
        }

    },
    buttonsHide: {
        display: "none",
    }
});
