import { createTheme } from "@mui/material/styles";

const mellon = createTheme({
    typography: {
        subtitle1: {
            fontSize: "16px",
        },
        subtitle2: {
            fontSize: "14px",
        },
        subtitle3: {
            fontSize: "12px",
        },
        h2: {
            '@media (max-width: 799px)': {
                fontSize: "36px", 
            },
        },
        h3: {
            fontSize: "50px",
            '@media (max-width: 799px)': {
                fontSize: "26px", 
            },
        },
        h4: {
            fontSize: "34px", 
            '@media (max-width: 799px)': {
                fontSize: "20px", 
            },
        },
        h5: {
            fontSize: "28px",
            '@media (max-width: 799px)': {
                fontSize: "20px", 
            },
        },
        h6: {
            fontSize: "22px",
            '@media (max-width: 799px)': {
                fontSize: "20px", 
            },
        },
        body1: {
            fontSize: "18px",
            '@media (max-width: 799px)': {
                fontSize: "16px"
            }
        },
        body2: {
            '@media (max-width: 799px)': {
                fontSize: "14px"
            }
        }
    },
    palette: {
        typography: {
            fontFamily: 'ZonaPro',
        },
        primary: {
            light: "#E8EDF5",
            main: "#123663",
            dark: "#081831",
            contrastText: "#FFFFFF",
        },
        secondary: {
            light: "#CBC6C5",
            main: "#BC9E6E",
            secondMain: "#909090",
            dark: "#262626",
            contrastText: "#666667",
        },
        error: {
            main: "#D9152D",
        },
        divider: "#666666",
        text: {
            primary: "#666666",
            secondary: "#909090",
            disabled: "#CCCCCC",
            dark: "#262626",
            hint: "#CCCCCC",
            icon: "#FFFFFF",
            light: "#FFFFFF",
        },
        common: {
            black: "#000",
            white: "#fff",
        },
        background: {
            default: "#FFFFFF",
        },
    },
    colors: {
        dividerBlue: "#5B718E",
        gray: "#A2A2A2",
        white: "#FFFFFF",
        appBarBackground: "#F5F5F5",
        red: "#E2001A",
        secondRed: "#ED1C24",
        darkRed: "#D7001B",
        lightRed: "#EC1C24",
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontFamily: "ZonaPro",
                    borderRadius: 0
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "ZonaPro",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
              input: {
                "&:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 100px #fff inset",
                  WebkitTextFillColor: "#000",
                  transition: "background-color 5000s ease-in-out 0s",
                },
              },
            },
          },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                html: {
                    height: "100%",
                },
                body: {
                    backgroundColor: "#F5F5F5",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                    height: "100%",
                    overflow: "auto!important",
                },
                "#root": {
                    height: "100%",
                },
                iframe: {
                    border: "none",
                },
            },
        },
    },
});

export default mellon;
