import { css } from "aphrodite";
import { CommonLayout } from "../../common/components/CommonLayout";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import appointmentCompletedIcon from "../../assets/icons/appointmentCompletedIcon.svg";
import { CommonLayoutTitle } from "../../common/components/CommonLayoutTitle";
import { CustomMainButton } from "../../common/components/CustomMainButton";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../Utils/routePath";
import { useSelector } from "react-redux";
import { Appointment, Media } from "../../../Models";
import { Typography } from "@mui/material";
import { CompleteCommonMobileLayout } from "../../common/components/CompleteCommonMobileLayout";

const AppointmentCompletedPage = () => {
    const [t] = useTranslation(["translation"]);
    const navigate = useNavigate();
    const bookCode = useSelector(Appointment.getBookCode);
    const webView = useSelector(Media.getIsWebView);

    const handleGoHome = () => {
        navigate(RoutePath.appointmentPage);
    };

    return (
        <CommonLayout>
            {webView ? 
            (
                <>
                    <CommonLayoutTitle
                        statusTitle={t("Success!")}
                        iconSrc={appointmentCompletedIcon}
                        title={t("Your Appointment has been booked")}
                        description={t(
                            "The Refference Number to your appointment has been sent to the e-mail and/or phone that you provided. Please make sure you don't lose this number, in the event that you wish to edit or cancel your appointment."
                        )}
                        stepDataLayoutVisible={true}
                    />
                    <div className={css(styles.container)}>
                        <Typography variant="h5">{t("Reference Number: ")}</Typography>
                        <Typography variant="h5">{bookCode || "-----"}</Typography>
                        <CustomMainButton
                            color="primary"
                            style={styles.button}
                            value={t("Back to Home")}
                            onClick={handleGoHome}
                        />
                    </div>
                </>
            ) : (
                <CompleteCommonMobileLayout
                    title={t("Success!")}
                    subTitle={t("Your Appointment has been booked")}
                    description={t(
                        "The Refference Number to your appointment has been sent to the e-mail and/or phone that you provided. Please make sure you don't lose this number, in the event that you wish to edit or cancel your appointment."
                    )}
                    appointmentValuesVisible={true}
                    referenceNumber={bookCode || "-----"}
                    handleGoHome={handleGoHome}
                />
            )}
        </CommonLayout> 
    );
};

export default AppointmentCompletedPage;