import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
    container: {
        display: "flex",
        flex: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginTop: 15,
        marginBottom: 22
    },
    titleDiv: {
        display: "flex",
        flex: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 10,
        paddingRight: 20,
        paddingLeft: 10,
    },
    button: {
        padding: 0
    },
    buttonDiv: {
        display: "flex",
        flex: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    }
})