import { StyleSheet } from "aphrodite";
import theme from "../../../common/theme/mellon";


export default StyleSheet.create({
    container: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        "@media (max-width: 799px)": {
             alignItems: "center"
        }
    },
    inputContainer: {
        background: theme.colors.white,
        borderBottom:  "1px solid " + theme.palette.primary.dark,
        padding: "12px 0px",
        display: "flex",
        width: "100%",
        maxWidth: "400px",
    },
    error: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    rowButton: {
        flexDirection: "row",
        justifyContent: "flexStart",
        alignItems: "center",
        gap: 15
    },
    errorField: {
        maxWidth: "400px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%"
    }
});