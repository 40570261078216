import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
    },
    button: {
        width: "400px",
        height: "60px",
        marginBottom: "20px",
    }
})