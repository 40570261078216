import { StyleSheet } from "aphrodite";
import theme from "../../../common/theme/mellon";

export default StyleSheet.create({
    layout: {
        color: theme.palette.primary.dark,
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        height: 336,
        width: "100%",
        flexGrow: 1,
        maxWidth: "450px",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        minWidth: "320px",
        overflowY: "auto",
        border: "2px solid" + theme.palette.primary.main,
        '@media (max-width: 799px)' : {
            maxWidth: "320px",
            minWidth: "100px"
        },
    },
    noAvailability: {
        color: theme.palette.text.secondary,
        margin: "auto"
    },
    insideGrid: {
        '@media (max-width: 338px)': {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
        }
    }
})