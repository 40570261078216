import { StyleSheet } from "aphrodite";
import theme from "../../../common/theme/mellon";

export default StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: 374,
        '@media (min-width: 799px)' : {
          width: 400,
        }
    },
    buttonDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        flexGrow: 1,
        maxWidth: 374,
        width: "100%",
        height: 80
    }, 
    valueButton: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        maxWidth: 342,
        height: "100%"
    },
    infoButton: {
        width: 60,
        height: "100%",
        borderLeft: `0.1px solid ${theme.colors.dividerBlue}`
    },
    secondary: { 
        borderLeft: `0.1px solid white`,
        background: `linear-gradient(to bottom, ${theme.palette.secondary.main}, ${theme.palette.secondary.main})`,
    },
    primary: { 
        background: `linear-gradient(to bottom, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
    },
    info: {
        background: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        padding: "12px 14px 30px 14px"
    },
    hideDIv: {
        display: "none"
    }
})