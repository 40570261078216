import { createReducer, stepLayoutItems, stepLayoutItemsByBranch, stepLayoutItemsEditAppointment, stepState } from "../../../Utils";
import * as type from "./types";

const initialState = {
    isLoading: false,
    stepItems: stepLayoutItems,
};

export default createReducer(initialState, {
    /* FETCH DEFAULT */
    [type.REQUEST](state, action) {
        return {
            ...state,
            isLoading: true,
            loadingCount: state.loadingCount + 1,
        };
    },

    [type.SUCCESS](state, action) {
        return {
            ...state,
            isLoading: false,
            data: action?.response?.data,
        };
    },

    [type.FAILURE](state, action) {
        return {
            ...state,
            isLoading: false,
            error: action.error,
        };
    },
    /* FETCH SERVICES */
    [type.REQUEST + type.FETCH_SERVICES](state, action) {
        return {
            ...state,
            services: {
                isLoading: true,
            },
        };
    },
    [type.SUCCESS + type.FETCH_SERVICES](state, action) {
        const organisationId = window.constants.RECT_APP_ORGANISATION_ID;
        return {
            ...state,
            services: {
                isLoading: false,
                data: action.response?.data?.filter(item => {
                    if (organisationId) {
                        return item.organisationId === organisationId
                    } else {
                        return true;
                    }
                })
            },
        };
    },
    [type.FAILURE + type.FETCH_SERVICES](state, action) {
        return {
            ...state,
            services: {
                isLoading: false,
                error: action.error,
            },
        };
    },
    /* FETCH BRANCHES */
    [type.REQUEST + type.FETCH_BRANCHES](state, action) {
        return {
            ...state,
            branches: {
                isLoading: true,
            },
        };
    },
    [type.SUCCESS + type.FETCH_BRANCHES](state, action) {
        const organisationId = window.constants.RECT_APP_ORGANISATION_ID;
        return {
            ...state,
            branches: {
                isLoading: false,
                data: action.response?.data?.filter(item => {
                    if (organisationId) {
                        return item.organisationId === organisationId
                    } else {
                        return true;
                    }
                }),
            },
        };
    },
    [type.FAILURE + type.FETCH_BRANCHES](state, action) {
        return {
            ...state,
            branches: {
                isLoading: false,
                error: action.error,
            },
        };
    },
    /* FETCH AVAILABILITY */
    [type.REQUEST + type.FETCH_AVAILABILITY](state, action) {
        return {
            ...state,
            availability: {
                isLoading: true,
            },
        };
    },
    [type.SUCCESS + type.FETCH_AVAILABILITY](state, action) {
        return {
            ...state,
            availability: {
                isLoading: false,
                data: action.response.data,
            },
        };
    },
    [type.FAILURE + type.FETCH_AVAILABILITY](state, action) {
        return {
            ...state,
            availability: {
                isLoading: false,
                error: action.error,
            },
        };
    },
    /* FETCH WAYS OF ACTION */
    [type.REQUEST + type.FETCH_WAYS_OF_ACTION](state, action) {
        return {
            ...state,
            waysOfAction: {
                isLoading: true,
            },
        };
    },
    [type.SUCCESS + type.FETCH_WAYS_OF_ACTION](state, action) {
        return {
            ...state,
            waysOfAction: {
                isLoading: false,
                data: action.response.data,
            },
        };
    },
    [type.FAILURE + type.FETCH_WAYS_OF_ACTION](state, action) {
        return {
            ...state,
            waysOfAction: {
                isLoading: false,
                error: action.error,
            },
        };
    },
    /* BOOK APPOINTMENT */
    [type.REQUEST + type.BOOK_APPOINTMENT](state, action) {
        return {
            ...state,
            book: {
                isLoading: true,
            },
        };
    },
    [type.SUCCESS + type.BOOK_APPOINTMENT](state, action) {
        return {
            ...state,
            book: {
                isLoading: false,
                data: action.response.data,
            },
            comfirmationModalVisibility: true,
        };
    },
    [type.FAILURE + type.BOOK_APPOINTMENT](state, action) {
        return {
            ...state,
            book: {
                isLoading: false,
                error: action.error,
            },
        };
    },
    /* FETCH APPOINTMENT INFO */
    [type.REQUEST + type.FETCH_APPOINTMENT_INFO](state, action) {
        return {
            ...state,
            appointmentInfo: {
                isLoading: true,
            },
        };
    },
    [type.SUCCESS + type.FETCH_APPOINTMENT_INFO](state, action) {
        return {
            ...state,
            appointmentInfo: {
                isLoading: false,
                data: action.response,
            },
            appointmentInfoModalVisibility: true,
        };
    },
    [type.FAILURE + type.FETCH_APPOINTMENT_INFO](state, action) {
        return {
            ...state,
            appointmentInfo: {
                isLoading: false,
                error: action.error,
            },
        };
    },
    /* CANCEL APPOINTMENT */
    [type.REQUEST + type.CANCEL_APPOINTMENT](state, action) {
        return {
            ...state,
            cancelAppointment: {
                isLoading: true,
            },
        };
    },
    [type.SUCCESS + type.CANCEL_APPOINTMENT](state, action) {
        return {
            ...state,
            cancelAppointment: {
                isLoading: false,
                data: action.response.data,
            },
            cancelModalVisibility: true,
        };
    },
    [type.FAILURE + type.CANCEL_APPOINTMENT](state, action) {
        return {
            ...state,
            cancelAppointment: {
                isLoading: false,
                error: action.error,
            },
        };
    },
    /* EDIT_APPOINTMENT */
    [type.REQUEST + type.EDIT_APPOINTMENT](state, action) {
        return {
            ...state,
            appointmentEdit: {
                isLoading: true,
            },
        };
    },
    [type.SUCCESS + type.EDIT_APPOINTMENT](state, action) {
        return {
            ...state,
            appointmentEdit: {
                isLoading: false,
                data: action.response.data,
            },
        };
    },
    [type.FAILURE + type.EDIT_APPOINTMENT](state, action) {
        return {
            ...state,
            appointmentEdit: {
                isLoading: false,
                error: action.error,
            },
        };
    },
    /* CUSTOM ACTIONS REDUCERS */
    [type.SET_USER_CATEGORY](state, action) {
        return {
            ...state,
            userCategory: action.payload,
        };
    },
    [type.SAVE](state, action) {
        return {
            ...state,
            ...action.payload,
        };
    },
    [type.SET_BANK_RELATION](state, action) {
        return {
            ...state,
            hasBankRelation: action.payload,
        };
    },
    [type.STEP_COMPLETED](state, action) {
        let newActiveStepIndex = null;
        let newItems = state.stepItems.map((item, index) => {
            if (item.id === action.payload) {
                newActiveStepIndex = index + 1;
                return {
                    ...item,
                    state: stepState.COMPLETE,
                };
            } else if ( newActiveStepIndex ) {
                return {
                    ...item,
                    state: newActiveStepIndex  === index ? stepState.ACTIVE : stepState.DEFAULT,
                };
            }
            return item;
        });

        return {
            ...state,
            stepItems: newItems,
        };
    },
    [type.SET_SELECTED_SERVICE](state, action) {
        return {
            ...state,
            selectedService: action.payload.id,
            selectedServiceName: action.payload.name,
            selectedEventTypeId: action.payload.eventTypeId
        };
    },
    [type.SET_SELECTED_BRANCH](state, action) {
        return {
            ...state,
            selectedBranch: action.payload.id,
            selectedBranchName: action.payload.name
        };
    },
    [type.SET_USER_INFORMATION](state, action) {
        return {
            ...state,
            userInformation: { ...action.payload },
        };
    },
    [type.SET_SELECTED_CHANNEL](state, action) {
        return {
            ...state,
            selectedChannel: action.payload,
        };
    },
    [type.SET_SELECTED_AVAILABLE_SLOT](state, action) {
        return {
            ...state,
            selectedAvailableSlot: { ...action.payload },
        };
    },
    [type.SET_APPOINTMENT_INFORMATION_MODAL_VISIBILITY](state, action) {
        return {
            ...state,
            appointmentInfoModalVisibility: action.payload,
        };
    },
    [type.SET_COMFIRMATION_MODAL_VISIBILITY](state, action) {
        return {
            ...state,
            comfirmationModalVisibility: action.payload,
        };
    },
    [type.SET_CANCEL_MODAL_VISIBILITY](state, action) {
        return {
            ...state,
            cancelModalVisibility: action.payload,
        };
    },
    [type.ACTIVATE_FIRST_STEP](state, action) {
        return {
            ...state,
            stepItems: state?.stepItems?.map((item, index) => {
                if (index === 0) {
                    return {
                        ...item,
                        state: stepState.ACTIVE,
                    };
                }
                return item;
            }),
        };
    },
    [type.RESET_STATE](state, action) {
        return {
            ...initialState,
            stepItems: stepLayoutItems,
        };
    },
    [type.INITIALIZE_STEP_ITEMS](state, action) {
        return {
            ...state,
            stepItems: action?.payload ? stepLayoutItemsByBranch : stepLayoutItems,
            isAppointmentByBranch: action?.payload,
        };
    },
    [type.SET_SELECTED_SLOT_DATA_VISIBILITY](state, action) {
        return {
            ...state,
            selectedSlotDataVisibility: action.payload,
        };
    },
    [type.INITIALIZE_EDIT_APPOINTMENT](state, action) {
        return {
            ...state,
            selectedChannel: action.payload?.channelId,
            selectedBranch: action.payload?.branchId,
            selectedService: action.payload?.serviceId,
            selectedServiceName: action.payload?.serviceName,
            selectedBranchName: action.payload?.branchName,
            selectedEventTypeId: action.payload?.eventTypeId,
            selectedSlotDataVisibility: true,
            selectedAvailableSlot: {
                start: action.payload?.startDate,
                end: action.payload?.endDate,
            },
            stepItems: stepLayoutItemsEditAppointment,
            editAppointmentMode: true,
        };
    },
    [type.EDIT_AGAIN](state, action) {
        return {
            ...state,
            appointmentEdit: {}
        };
    },
});
