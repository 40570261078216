/* eslint-disable no-use-before-define */
// eslint-disable-next-line import/no-unresolved
import Cookies from 'js-cookie';
import authHeader from './authHeader';
import tokenHasExpired from './tokenHasExpired';
import Logger from "./Logger";

const _apiHost = window.constants.REACT_APP_API_URL;
const httpRequestLoggingEnabled = process.env.REACT_APP_HTTP_REQUEST_LOGGIN_ENABLED === 'true';

const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};

const getUserId = () => {
    const tokens = Cookies.get('tokens');
    if (!tokens) return '';
    const id_Token = parseJwt(JSON.parse(tokens).id_Token);
    return id_Token.sub;
};

const objectToQueryString = (obj) => {
    return Object.keys(obj)
        .map((key) => key + '=' + obj[key])
        .join('&');
};

const generateErrorResponse = async (error) => {
    return error.text().then((text) => {
        const errorObject = JSON.parse(text);
        const errorResponse = {
            status: 'error',
            code: errorObject?.errorCode,
            message: errorObject?.error,
        };
        return errorResponse
    });
};

/**
 * @param {Response} response The response object
 * @param {Logger} log Optional logger instance
 * @return {String} The Response text
 */
const handleResponse = async (response, log = null) => {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // global.window.location.href = window.location.hostname;
            // global.window.location.href = "";
        } else if (response.status === 500 ) {
            // clear cookie;
            // global.window.location.href = window.location.hostname;
        }
        const error = response;
        const errorResult =  await generateErrorResponse(error);
        log?.error(errorResult, response.status + " - REQUEST FAILED.");
        return errorResult;
    }

    return response.text().then((text) => {
        log?.success( text,response.status + " - REQUEST SUCCEEDED.")
        return text ? JSON.parse(text) : 'No response text';
    });
};

/**
 * @param {Response} response The response object
 * @param {Logger} log Optional logger instance
 * @return {Blob|null} The blob response
 */
const handleBlobResponse = async (response, log = null) => {
    if (!response.ok) {
        log?.error(response.status + " - REQUEST FAILED.")
        if (response.status === 401) {
        } else if (response.status === 500 ) {
        }
        const error = response;
        return null;
    }
    return response.blob().then((blob) => {
        log?.success(`BLOB (binary large object) response`)
        return blob;
    });
};
const request = async (url, params, method = 'GET') => {
    const log = httpRequestLoggingEnabled ? new Logger({logUuid: false}) : null;
    log?.request(params, method + " " + url);
    const headerAuth = await authHeader();
    const options = {
        method,
        headers: headerAuth,
    };
    if (params) {
        if (params.header) {
            options.headers = params.header;
        }
        if (method === 'GET' && params.body) {
            url += '?' + objectToQueryString(params.body);
        } else {
            options.body = JSON.stringify(params.body);
        }
    }
    // TODO DELETE COMMENT
    //const response = await fetch(_apiHost + url, options);
    const response = await fetch(url, options);
    if ( params?.isBlob ) {
        return handleBlobResponse(response, log);
    }
    return handleResponse(response, log);
};

const requestRedirectUri = async (url, params, method = 'POST') => {
    const options = {
        method,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    };

    options.body = params.body;

    const response = await fetch(_apiHost + url, options);

    return handleResponse(response);
};

const requestLogin = async (url, params, method = 'POST') => {
    const options = {
        method,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    };

    options.body = params.body;

    const response = await fetch(_apiHost + url, options);

    return handleResponse(response);
};

const login = (url, params) => {
    return requestLogin(url, params);
};

const logout = async () => {
    const cookiesData = Cookies.get('tokens');
    const tokenId = JSON.parse(cookiesData).id_Token;
    const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: {
            data: {
                idToken: tokenId,
                postLogoutRedirectUri: process.env.REACT_APP_CALLBACK_URL,
            },
        },
    };
    const url = '/oauth2/logout';
    // localStorage.removeItem('user');
    localStorage.removeItem('lastRoute');

    if (typeof options.body !== 'string' || !(options.body instanceof String)) {
        options.body = JSON.stringify(options.body);
    }

    const response = await fetch(_apiHost + url, options);

    // eslint-disable-next-line no-restricted-globals
    // if (response) location.reload(true);
    return handleResponse(response);
};

const refreshToken = async () => {
    //Fetch auth token
    try {
        const _apiHost = window.constants.REACT_APP_API_URL;
        const authUrl = _apiHost + "/authentication/token/refresh";
        const refresh = {
            "refresh": JSON.parse(Cookies.get('tokens')).refreshToken
        };
        const options = {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'text/plain'
            },
            body: refresh

        };
        options.body = JSON.stringify(options.body);
        const response = await fetch(authUrl, options);
        const responseData = await response.json();
        if (responseData.data) {
          const data = JSON.stringify(responseData.data);
          Cookies.set("tokens", data);
        }
    } catch (e) {
        console.log(`Error in refreshToken: `, e);
    }
};

const get = async (url, params) => {
    if (tokenHasExpired()) {
        await refreshToken();
    }
    return request(url, params);
};

const post = async (url, params) => {
    if (tokenHasExpired()) {
        await refreshToken();
    }
    return request(url, params, 'POST');
};

const put = async (url, params) => {
    return request(url, params, 'PUT');
};

const patch = async (url, params) => {
    return request(url, params, 'PATCH');
};

const remove = async (url, params) => {
    if (tokenHasExpired()) {
        await refreshToken();
    }
    return request(url, params, 'DELETE');
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    requestRedirectUri,
    login,
    logout,
    get,
    post,
    put,
    patch,
    remove,
    getUserId,
    refreshToken
};
