import Cookies from 'js-cookie';

const authHeader = async () => {
  // return authorization header with jwt token
  let tokens = Cookies.get('tokens');
  tokens = tokens && JSON.parse(Cookies.get('tokens'));
  if (tokens) {
    return {
      Authorization: `Bearer ${tokens.accessToken}`,
      'Content-Type': 'application/json',
    };
  } else {
    //Fetch auth token
    const _apiHost = window.constants.REACT_APP_API_URL;
    const authUrl = _apiHost + "/authentication/token/access";
    const userCredentials = {
      "username": "SuperAdmin",
      "password": "Qtkt4r-kuZ" 
    };
    const options = {
        method: 'POST',
        headers: {
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
        },
        body: userCredentials

    };
    options.body = JSON.stringify(options.body);
    try {
      const response = await fetch(authUrl, options);
      const responseData = await response.json();
      const accessToken = responseData.data.accessToken;
      if (responseData.data) {
        const data = JSON.stringify(responseData.data);
        Cookies.set("tokens", data);
      }
      return {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
    } catch (e) {
      console.log(`Error getting auth header: ${e.message}`, e);
    }
  }
  return {};
};

export default authHeader;
