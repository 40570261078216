import { StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    button: {
        color: "white",
        borderRadius: "0px",
        transition: "width 0.3s ease-in-out, background-color 0.3s ease-in-out",
        height: 60,
        width: 60,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center"
    },
    buttonHovered: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        width: 400,
        transition: "width 0.3s ease-in-out, background-color 0.3s ease-in-out"
    },
    text: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        margin: "auto"
    },
    icon: {}
});

export default styles;
