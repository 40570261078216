import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './State/store';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';


const rootContainer = document.getElementById('root')
const root = createRoot(rootContainer);
root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </I18nextProvider>
    </Provider>
);
// ReactDOM.render(
//     <Provider store={store}>
//         <I18nextProvider i18n={i18n}>
//             <App />
//         </I18nextProvider>
//     </Provider>
//     ,
//
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
