import { StyleSheet } from "aphrodite";
import theme from "../../../common/theme/mellon";

export default StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginBottom: 15,
        "@media (max-width: 799px)": {
            flexDirection: "column",
        },
        width: "100%"
    },
    textValue: {
        overflow: "hidden",      
        whiteSpace: "nowrap",      
        textOverflow: "ellipsis",   
        flexShrink: 1,
        maxWidth: "unset",  
        "@media (max-width: 1320px)": {
            maxWidth: "130px"     
        },                
        "@media (max-width: 799px)": {
            width: "100%",
            maxWidth: "unset"     
        },
    },
    rowContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        overflow: "hidden",  
        whiteSpace : "nowrap",  
        textOverflow: "ellipsis",
        color: theme.palette.primary.dark
    },
    marginEmptyData: {
        marginTop: 15,
    },
})