import { StyleSheet } from "aphrodite";
import theme from "../../theme/mellon";

export default StyleSheet.create({
    layout: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-start",
        paddingLeft: "6%",
        paddingRight: "6%",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        color: theme.palette.primary.dark,
    },
    mainLayout: {
        height: "100%",
        flexGrow: 1,
    }
});
