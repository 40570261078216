import React from "react";
import { css } from "aphrodite";
import styles from "./styles";
import { Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router";
import bankOfGreeceLogo from "../../../assets/icons/bankOfGreeceLogo.svg";
import bankOfGreeceLogoMobile from "../../../assets/icons/bankOfGreeceLogoMobile.svg";
import i18n from "../../../../i18n";
import theme from "../../../common/theme/mellon";

const CustomAppBar = ({ webView = true }) => {
    const navigate = useNavigate();
    const [lang, setLang] = React.useState(i18n?.language?.toUpperCase());

    const handlLogoButtonClicked = () => {
        navigate("/");
    };

    const handleChangeLang = (lang) => {
        setLang(lang.toUpperCase());
        i18n.changeLanguage(lang);
    };

    return (
        <div className={css(webView ? styles.container : styles.mobileAppbar)}>
            <IconButton disableRipple onClick={handlLogoButtonClicked}>
                <img
                    alt=""
                    src={webView ? bankOfGreeceLogo : bankOfGreeceLogoMobile}
                />
            </IconButton>
            <div className={css(webView ? styles.row : styles.rowMobile)}>
                <Button
                    onClick={() => handleChangeLang("en")}
                    className={css(styles.button)}
                    sx={{
                        color:
                            lang === "EN"
                                ? theme.palette.secondary.main
                                : theme.palette.common.white,
                    }}
                >
                    EN
                </Button>
                <div className={css(styles.divider)} />
                <Button
                    onClick={() => handleChangeLang("gr")}
                    variant="text"
                    className={css(styles.button)}
                    sx={{
                        color:
                            lang === "GR"
                                ? theme.palette.secondary.main
                                : theme.palette.common.white,
                    }}
                >
                    GR
                </Button>
            </div>
        </div>
    );
};

const memoizedCustomAppBar = React.memo(CustomAppBar);
export { memoizedCustomAppBar as CustomAppBar };
