import { dispatchHelper } from "../../Utils";
import Model from "../Model";
import * as type from "../../State/modules/media/types";
import { createSelector } from "reselect";
import { store } from "../../State/store";
const media = () => store.getState().media;

class Media extends Model {
    constructor(data) {
        /** Configure your model here **/
        super(data, {
            // params object and any properties inside are optional. Include only what you need or nothing at all
            // Only fields in _fillable will be writeable. Empty or absent _fillables  will not evaluate at all
            _fillable: ["webView"],
            // Fields in _guarded will not be writeable
            _guarded: [],
            // Fields in _hidden will not be included in the "values" (values returns an object with this instance's data fields) result
            _hidden: [],
            // Fields in _casts will be type casted before returned. e.g. in the example below id property will be returned as string regardless
            _casts: {
                id: String,
                isPaid: Number,
                paidAt: Boolean,
            },
            // Specify custom getters and setters for fields. If a field is not in _overrides, the default getter and setter will be used.
            _overrides: {},
        });
    }

    /** ACTIONS **/
    static setWebView = (value) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(type.SET_WEB_VIEW, value)
        );
    };

    /** SELECTORS **/
    static getIsWebView = createSelector(media, (appointmentState) => {
        return appointmentState.webView;
    });
}

export default Media;
