const enTranslation = require('./en/translation.json');
const elTranslation = require('./gr/translation.json');


const resources = {
  en: {
    translation: enTranslation,
  },
  gr: {
    translation: elTranslation,
  },
};

export default resources;
