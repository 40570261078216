import { StyleSheet } from "aphrodite";
import theme from "../../common/theme/mellon";


export default StyleSheet.create({  
    row: {
        margin: "33px 0px 60px 0px",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start"
    },
    stepsContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%"
    }, 
    titleRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        gap: 24,
        color: theme.palette.primary.dark
    },
    button: {
        width: "100%",
        height: "60px",
        margin: "30px 0px",
        maxWidth: "400px"
    },
    mobileButton: {
        width: "100%",
        height: "60px",
        margin: "25px 0px",
        maxWidth: "400px"
    },
    disableStyle: {
        color: theme.colors.gray,
        background: theme.palette.primary.light
    },
    mobileContainer: {
        width: "100%",
        maring: "25px 0px",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
    },
    errorMessage: {
        paddingBottom: "9px"
    }
});