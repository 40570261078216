import Cookies from 'js-cookie';

const tokenHasExpired = () => {

  const tokens = Cookies.get('tokens');
  if (!tokens) {
    return false;
  }
  try {
    const accessToken = JSON.parse(tokens).accessToken;
    let decoded = JSON.parse(atob(accessToken.split('.')[1]));
    if (Date.now() >= decoded.exp * 1000) {
        return true;
    }
  }
  catch (e) {
      console.log("Error in token expired check: ", e);
  }

  return false;

};

export default tokenHasExpired;
