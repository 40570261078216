import React, { useEffect } from "react";
import { css } from "aphrodite";
import styles from "./styles";
import { Box, Button, Fade, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../Utils/routePath";
import { useTranslation } from "react-i18next";
import { CustomRectangleIconButton } from "../../common/components/CustomRectangleIconButton";
import appointmentIcon from "../../assets/icons/appointmentIcon.svg";
import editAppointmentIcon from "../../assets/icons/editAppointmentIcon.svg";
import appointmentIconMobile from "../../assets/icons/appointmentIconMobile.svg";
import editAppointmentIconMobile from "../../assets/icons/editAppointmentIconMobile.svg"
import { Appointment, Media } from "../../../Models";
import { useSelector } from "react-redux";

const AppointmentPage = () => {
    const navigate = useNavigate();
    const [t] = useTranslation(['translation']);
    const [extraButtonsVisible, setExtraButtonsVisible] = React.useState(false);
    const webView = useSelector(Media.getIsWebView);

    useEffect(() => {
        Appointment.resetState();
    }, []);

    const handleNewAppointmentClicked = () => {
        setExtraButtonsVisible(true);
    }

    const handleNavigateToFirstStepByService = () => {
        Appointment.initializeStepItems(false);
        Appointment.activateFirstStep();
        navigate(RoutePath.selectServicePage);
    }

    const handleNavigateToFirstStepByBranch = () => {
        Appointment.initializeStepItems(true);
        Appointment.activateFirstStep();
        navigate(RoutePath.selectBranchPage);
    }

    const handleNavigateToEditNewAppointment = () => {
        navigate(RoutePath.findYourAppointmentPage);
    }

    return (
        <div className={css(styles.container)}>
            <Box 
                className={css(styles.column)}
            >
                <CustomRectangleIconButton
                    iconSrc={webView ? appointmentIcon : appointmentIconMobile}
                    onClick={handleNewAppointmentClicked}
                />
                <Typography
                    color="primary"
                    variant="h4"
                >
                    {t("New Appointment")}
                </Typography>
                <Fade in={extraButtonsVisible} timeout={500}>
                    <div className={css([styles.column, styles.buttonsColumn, !extraButtonsVisible && styles.buttonsHide])}>
                        <Button
                            className={css(styles.extraButton)}
                            variant="contained"
                            color= "secondary"
                            disableElevation
                            onClick={handleNavigateToFirstStepByService}
                        >
                            <Typography variant="h5">
                                {t("By Service")}
                            </Typography>
                        </Button>
                        <Button
                            className={css(styles.extraButton)}
                            variant="contained"
                            color= "secondary"
                            disableElevation
                            onClick={handleNavigateToFirstStepByBranch}
                        >   
                             <Typography variant="h5">
                                {t("By Branch")}
                             </Typography>
                        </Button>
                    </div>
                </Fade>
            </Box>
            <Box 
                className={css(styles.column)}
            >
                <CustomRectangleIconButton
                    onClick={handleNavigateToEditNewAppointment}
                    iconSrc={webView ? editAppointmentIcon : editAppointmentIconMobile}
                />
                <Typography
                    color="primary"
                    variant="h4"
                >
                    {t("Edit Appointment")}
                </Typography>
            </Box>
        </div>
    )
};

const memoizedAppointmentPage = React.memo(AppointmentPage);
export { memoizedAppointmentPage as AppointmentPage };
