import { combineReducers } from "redux";

import appointment from "./appointment";
import media from "./media";

const rootReducer = combineReducers({
    appointment,
    media
});

export { rootReducer };
