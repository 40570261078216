import { css } from "aphrodite";
import { CommonLayout } from "../../common/components/CommonLayout";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import appointmentCompletedIcon from "../../assets/icons/appointmentCompletedIcon.svg";
import { CommonLayoutTitle } from "../../common/components/CommonLayoutTitle";
import { CustomMainButton } from "../../common/components/CustomMainButton";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../Utils/routePath";
import { useSelector } from "react-redux";
import { CompleteCommonMobileLayout } from "../../common/components/CompleteCommonMobileLayout";
import { Appointment, Media } from "../../../Models";

const AppointmentEditCompletedPage = () => {
    const [t] = useTranslation(["translation"]);
    const navigate = useNavigate();
    const webView = useSelector(Media.getIsWebView);

    const handleEditAgain = () => {
        Appointment.editAgain();
        navigate(RoutePath.selectServicePage);
    };

    const handleGoHome = () => {
        navigate(RoutePath.appointmentPage);
    };

    return webView ? (
        <CommonLayout>
            <CommonLayoutTitle
                statusTitle={t("Done!")}
                iconSrc={appointmentCompletedIcon}
                title={t("Your Appointment has been edited")}
                description={t(
                    "Your appointment has been changed to match your new desires. If you have changed your mind you can edit your appointment again, or return to Home."
                )}
                stepDataLayoutVisible={true}
            />
            <div className={css(styles.container)}>
                <CustomMainButton
                    fixedSize={false}
                    color="secondary"
                    style={styles.button}
                    value={t("Edit again")}
                    onClick={handleEditAgain}
                />
                <CustomMainButton
                    fixedSize={false}
                    color="primary"
                    style={styles.button}
                    value={t("Back to Home")}
                    onClick={handleGoHome}
                />
            </div>
        </CommonLayout>
    ) : (
        <CommonLayout>
            <CompleteCommonMobileLayout
                title={t("Done!")}
                subTitle={t("Your Appointment has been edited")}
                description={t(
                    "Your appointment has been changed to match your new desires. If you have changed your mind you can edit your appointment again, or return to Home."
                )}
                appointmentValuesVisible={true}
                handleGoHome={handleGoHome}
                econdaryButtonColor="secondary"
                secondaryButtonOnClick={handleEditAgain}
                secondaryButtonTitle={t("Edit again")}
                secondaryButtonVisible={true}
            />
        </CommonLayout>
    );
};

export default AppointmentEditCompletedPage;
