import { css } from "aphrodite";
import styles from "./styles";
import { Typography } from "@mui/material";
import StepDataInfo from "../StepDataInfo/StepDataInfo";
import { useTranslation } from "react-i18next";
import { CustomMainButton } from "../CustomMainButton";

const CompleteCommonMobileLayout = ({
    title,
    subTitle,
    referenceNumber,
    description,
    appointmentValuesVisible = false,
    secondaryButtonVisible = false,
    secondaryButtonTitle = "Edit again",
    secondaryButtonColor = "secondary",
    handleGoHome = () => {},
    secondaryButtonOnClick = () => {},
}) => {
    const [t] = useTranslation(["translation"]);

    return (
        <div className={css(styles.container)}>
            {title && 
                <Typography variant="h3" className={css(styles.title)}>
                    {title}
                </Typography>
            }
            {subTitle && (
                <Typography
                    variant="h5"
                    className={css([styles.alignStart, styles.subTitle])}
                >
                    {subTitle}
                </Typography>
            )}
            {appointmentValuesVisible && <StepDataInfo />}
            {referenceNumber && (
                <>
                    <Typography
                        variant="h3"
                        className={css([styles.referenceNumberTitle])}
                    >
                        {t("Reference Number: ")}
                    </Typography>
                    <Typography variant="h2">{referenceNumber}</Typography>
                </>
            )}
            {description && (
                <Typography
                    variant="body1"
                    className={css([styles.alignStart, styles.description])}
                >
                    {description}
                </Typography>
            )}
            {secondaryButtonVisible && (
                <CustomMainButton
                    fixedSize={false}
                    value={secondaryButtonTitle}
                    color={secondaryButtonColor}
                    textColor="white"
                    style={[styles.mobileButton]}
                    onClick={secondaryButtonOnClick}
                />
            )}
            <CustomMainButton
                fixedSize={false}
                value={t("Back to Home")}
                textColor="inherit"
                style={[styles.mobileButton, styles.gap]}
                onClick={handleGoHome}
            />
        </div>
    );
};

export default CompleteCommonMobileLayout;
