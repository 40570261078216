import { StyleSheet } from 'aphrodite';
import theme from '../../common/theme/mellon';
export default StyleSheet.create({
  container: {
    height: '100vh',
    padding: 19,
    backgroundColor: theme.colors.background,
  },
  title: {
    fontSize: 20,
  },
  button: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    background: '#1890ff',
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
    transition: '.3s background',
    ':hover': {
      background: '#40a9ff',
    },
  },
});
