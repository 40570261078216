import { StyleSheet } from "aphrodite"; 
import theme from "../../../common/theme/mellon";

export default StyleSheet.create({
    branchList: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-start",
        paddingLeft: "6%",
        paddingRight: "6%",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        overflowY: "auto",
        minHeight: 360,
        height: "calc(100vh - 690px)",
        '@media (max-width: 799px)': {
            height: "calc(100vh - 540px)",
            padding: "0px"
        }
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
        margin: "20px 0px",
        paddingBottom: "20px",
        color: theme.palette.primary.dark,
        '@media (max-width: 799px)': {
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "0px 28px",
            margin: "15px 0px",
            paddingBottom: "15px"
        }
    },
    branchInfoColumn: {
        flexDirection: "column",
        display: "flex",
    },
    button: {
       width: "100%",
       marginTop: "7px",
    },
    centerEmptyContainer: {
        margin: "auto"
    }
});