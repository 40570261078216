import React from 'react';
import { css } from 'aphrodite';

import styles from './styles';
import { useTranslation } from 'react-i18next';

const DefaultPage = (props) => {
  const [t] = useTranslation(["translation"]);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.title)}>
        {t("404. PAGE NOT FOUND")}
      </div>
    </div>
  );
};

const memoizedDefaultPage = React.memo(DefaultPage);
export { memoizedDefaultPage as DefaultPage };
