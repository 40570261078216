import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: 27,
        gap: 5
    },
    button: {
        width: "400px",
        height: "60px",
        marginTop: "40px"
    }
})