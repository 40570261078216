import { css } from "aphrodite";
import styles from "./styles";


const CommonLayout = ({ mainLayout = true, children }) => {
    return (
        <div className={css([styles.layout, mainLayout && styles.mainLayout])}>
            {children}
        </div>
    );
}

export default CommonLayout;