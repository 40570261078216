import Logger from "./Logger";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { RoutePath } from "./routePath";
import { toast } from "react-toastify";

const dateComparison = (var1, var2) => {
    // date1,2 are arrays. 0th position contains day, 1st contains month and 2nd the year
    let date1 = var1.split("/");
    let date2 = var2.split("/");
    if (date1.length <= 1) {
        date1 = var1.split("-");
    }
    if (date2.length <= 1) {
        date2 = var2.split("-");
    }
    for (let i = 2; i >= 0; i -= 1) {
        if (date1[i] !== date2[i]) {
            return parseInt(date1[i], 10) > parseInt(date2[i], 10);
        }
    }
    return false;
};

/** returns true if var 2 should be sorted  before var1 */
const compareVariables = (var1, var2, comparisonType) => {
    if (comparisonType === "num") {
        return var1 > var2;
    }
    if (comparisonType === "txt") {
        // Logger.log('var1, ' + var1 + 'var2 ' + var2);
        if (!var1 && var2) {
            return true;
        }
        if (!var2 && var1) {
            return false;
        }
        if (!var2 && !var1) {
            return false;
        }
        return var1.localeCompare(var2) === 1;
    }
    if (comparisonType === "date") {
        return dateComparison(var1, var2);
    }
    if (comparisonType === "logical") {
        // the logic in this comparison is that false should only be after truth
        // so it returns true ONLY when we have a true statement after a false one
        return !var1 && var2;
    }
};

// default sort is ASCENDING (from small to big)
const sortArrayAscendingDescending = (array, col, asc, comparisonType) => {
    /** This var is true if we have changed the position of elements in the array in the current loop */
    let haveSwappedElements = true;
    /** Col 1 and col 2 are used because in order to get some elements we have to travel an extra step. for example
     * if we want to get id we go array[0][id] but,
     * if we want to get swiftNumber we go array[0][primaryBank][swiftNumber]
     */
    const col1 = col.split(".")[0];
    const col2 = col.split(".")[1];
    while (haveSwappedElements) {
        haveSwappedElements = false;
        for (let i = 0; i < array.length - 1; i += 1) {
            // if we have 2 cols to take into consideration, we use them both
            const var1 = col2 ? array[i][col1][col2] : array[i][col1];
            const var2 = col2 ? array[i + 1][col1][col2] : array[i + 1][col1];
            const comparison = compareVariables(var1, var2, comparisonType);
            // if a swap HAS to be done, we do it and we also raise the flag to true so that we can re-check the array for new swaps
            if (comparison) {
                const temp = array[i + 1];
                array[i + 1] = array[i];
                array[i] = temp;
                haveSwappedElements = true;
            }
        }
    }
    return asc ? array : array.reverse();
};

const dateFilter = (array, date, dateField) => {
    const filteredArray = [];
    // dateField is the field that will get filtered
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][dateField] === date) {
            filteredArray.push(array[i]);
        }
    }
    return filteredArray;
};

const statusFilter = (array, status, statusField) => {
    const filteredArray = [];
    // statusField is the field that will get filtered
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][statusField] === status) {
            filteredArray.push(array[i]);
        }
    }
    return filteredArray;
};

const countryFilter = (array, status, statusField) => {
    const filteredArray = [];
    // statusField is the field that will get filtered
    for (let i = 0; i < array.length; i += 1) {
        if (
            array[i][statusField]
                .toLowerCase()
                .indexOf(status.toLowerCase()) !== -1
        ) {
            filteredArray.push(array[i]);
        }
    }
    return filteredArray;
};

const wordFilter = (array, word) => {
    const filteredArray = [];
    for (let i = 0; i < array.length; i += 1) {
        let containsWord = false;
        if (
            array[i]?.content &&
            array[i]?.content.toLowerCase().indexOf(word.toLowerCase()) !== -1
        ) {
            containsWord = true;
        }
        if (
            array[i]?.createdAt &&
            array[i]?.createdAt.toLowerCase().indexOf(word.toLowerCase()) !== -1
        ) {
            containsWord = true;
        }
        if (
            array[i]?.title &&
            array[i]?.title.toLowerCase().indexOf(word.toLowerCase()) !== -1
        ) {
            containsWord = true;
        }
        if (containsWord) {
            // keep the current row for display
            filteredArray.push(array[i]);
        }
    }
    return filteredArray;
};

const applyWordFilter = (array, word, fields, isNotJSON) => {
    if (!word) {
        return array;
    }
    const filteredArray = [];
    for (let i = 0; i < array.length; i += 1) {
        if (!isNotJSON) {
            for (let j = 0; j < fields.length; j += 1) {
                if (
                    (array[i][fields[j]] + "" || "")
                        .toLowerCase()
                        .indexOf(word) !== -1
                ) {
                    filteredArray.push(array[i]);
                }
            }
        }
    }
    return filteredArray;
};

const applyFilters = (array, filters) => {
    if (filters) {
        for (let i = 0; i < filters.length; i += 1) {
            if (filters[i].type === "word") {
                array = wordFilter(array, filters[i].word);
            } else if (filters[i].type === "date") {
                array = dateFilter(
                    array,
                    filters[i].date,
                    filters[i].dateField
                );
            } else if (filters[i].type === "status") {
                array = statusFilter(
                    array,
                    filters[i].status,
                    filters[i].statusField
                );
            }
        }
    }
    return array;
};

const applyFiltersCustom = (array, filters) => {
    if (filters) {
        for (let i = 0; i < filters.length; i += 1) {
            if (filters[i].type === "word") {
                array = wordFilter(array, filters[i].word);
            } else if (filters[i].type === "date") {
                array = dateFilter(
                    array,
                    filters[i].date,
                    filters[i].dateField
                );
            } else if (filters[i].type === "status") {
                array = countryFilter(
                    array,
                    filters[i].status,
                    filters[i].statusField
                );
            }
        }
    }
    return array;
};

const fixDateTime = (date, withTime = true) => {
    const validDate = new Date(date);
    const year = validDate.getFullYear();
    let month = validDate.getMonth() + 1;
    let dt = validDate.getDate();
    if (dt < 10) {
        dt = "0" + dt;
    }
    if (month < 10) {
        month = "0" + month;
    }
    const d = dt + "/" + month + "/" + year;
    let time = "";
    if (withTime) {
        let hours = validDate.getHours();
        if (hours < 10) {
            hours = "0" + hours;
        }
        let minutes = validDate.getMinutes();
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        let seconds = validDate.getSeconds();
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        time = `${hours}:${minutes}:${seconds}`;
    }

    return !withTime ? d : `${d} ${time}`;
};

const formatMoney = (
    amount,
    decimalCount = 2,
    decimal = ".",
    thousands = ","
) => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        // eslint-disable-next-line radix
        const i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        const j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - i)
                      .toFixed(decimalCount)
                      .slice(2)
                : "")
        );
    } catch (e) {
        Logger.log(e);
    }
};

const downloadFile = (blob, fileName) => {
    try {
        if (blob?.size > 0) {
            const link = document.createElement("a");
            // create a blobURI pointing to our Blob
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            return {
                success: true,
                message: "OK",
            };
            // in case the Blob uses a lot of memory
            // setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        } else {
            Logger.log("Empty file");
            return {
                success: false,
                message: "Received file was empty",
            };
        }
    } catch (e) {
        Logger.log(e);
        return {
            success: false,
            message: e.message,
        };
    }
};

const isObject = (objValue) => {
    return (
        objValue &&
        typeof objValue === "object" &&
        objValue.constructor === Object
    );
};

const getTimeFromISOString = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
};

const isSameDate = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return (
        d1.getUTCFullYear() === d2.getUTCFullYear() &&
        d1.getUTCMonth() === d2.getUTCMonth() &&
        d1.getUTCDate() === d2.getUTCDate()
    );
};

const validatePhoneNumber = (value) => {
    try {
        const phoneNumber = parsePhoneNumberFromString(value);
        if (!phoneNumber || !phoneNumber.isValid()) {
            return false;
        }
        return true;
    } catch (error) {
        return false;
    }
};

const isDateGreaterThan = (date1, date2) => {
    return new Date(date1) > new Date(date2);
};

function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const optionsDate = { year: "numeric", month: "long", day: "numeric" };
    const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: false };
    const formattedDate = new Intl.DateTimeFormat("en-US", optionsDate).format(
        date
    );
    const formattedTime = new Intl.DateTimeFormat("en-US", optionsTime).format(
        date
    );
    return `${formattedDate} - ${formattedTime}`;
}

function formatDateToDDMMYYYY(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

const shallowEqual = (obj1, obj2) => {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
    }
    for (let key in obj1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
    return true;
};

const getNextStepPath = (currentStep, steps) => {
    return (
        steps.find((step) => step.id === currentStep)?.nextStepPath ||
        RoutePath.home
    );
};
const notify = (message, type = "default", autoClose = false) => {
    toast(message, {
        type: type,
        position: "bottom-left",
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

const calculateEndTime = (start, duration) => {
    try {
        // Parse the start time as a Date object
        const startTime = new Date(start);
        if (isNaN(startTime.getTime())) {
            throw new Error("Invalid start time value");
        }

        // Extract duration values from the ISO 8601 duration format
        const durationRegex = /P(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
        const match = duration.match(durationRegex);
        if (!match) {
            throw new Error("Invalid duration format");
        }

        const [, days = 0, hours = 0, minutes = 0, seconds = 0] = match.map(
            (value) => parseInt(value || 0, 10)
        );

        // Calculate the total duration in milliseconds
        const durationInMilliseconds =
            days * 24 * 60 * 60 * 1000 +
            hours * 60 * 60 * 1000 +
            minutes * 60 * 1000 +
            seconds * 1000;

        // Add the duration to the start time
        const endTime = new Date(startTime.getTime() + durationInMilliseconds);

        // Return the end time in the same ISO 8601 format
        return endTime.toISOString();
    } catch (error) {
        console.error(error.message);
        return "";
    }
};

export {
    shallowEqual,
    getNextStepPath,
    formatDateToDDMMYYYY,
    formatDate,
    sortArrayAscendingDescending,
    applyFilters,
    applyFiltersCustom,
    fixDateTime,
    formatMoney,
    applyWordFilter,
    downloadFile,
    isObject,
    getTimeFromISOString,
    isSameDate,
    validatePhoneNumber,
    isDateGreaterThan,
    notify,
    calculateEndTime,
};
