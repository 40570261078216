import { Button, styled, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { css } from "aphrodite";
import styles from "./styles";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 17,
        maxWidth: 400,
        padding: "14px 20px",
        fontFamily: "ZonaPro",
    },
}));

const CustomMainButton = ({
    value,
    toolTipValue,
    style,
    isDisabled = false,
    variant = "contained",
    color = "primary",
    isSelected = false,
    disableElavation = true,
    selectedColor = "secondary",
    fixedSize = true,
    textColor = "white",
    textVariant = "h6",
    onClick = () => {},
}) => {
    return toolTipValue ? (
        <LightTooltip title={toolTipValue}>
            <Button
                disabled={isDisabled}
                variant={variant}
                color={isSelected ? selectedColor : color}
                disableElevation={disableElavation}
                className={css([
                    styles.button,
                    fixedSize && styles.fixedSize,
                    style,
                ])}
                onClick={onClick}
            >
                <Typography variant={textVariant} color={textColor}>
                    {value}
                </Typography>
            </Button>
        </LightTooltip>
    ) : (
        <Button
            disabled={isDisabled}
            variant={variant}
            color={isSelected ? selectedColor : color}
            disableElevation={disableElavation}
            className={css([
                styles.button,
                fixedSize && styles.fixedSize,
                style,
            ])}
            onClick={onClick}
        >
            <Typography variant={textVariant} color={textColor}>
                {value}
            </Typography>
        </Button>
    );
};

export default CustomMainButton;
