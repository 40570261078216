import { dispatchHelper, stepState } from "../../Utils";
import Model from "../Model";
import * as type from "../../State/modules/appointment/types";
import { createSelector } from "reselect";
import { store } from "../../State/store";
import { AppointmentService } from "./services";
const appointment = () => store.getState().appointment;

class Appointment extends Model {
    constructor(data) {
        /** Configure your model here **/
        super(data, { // params object and any properties inside are optional. Include only what you need or nothing at all
            // Only fields in _fillable will be writeable. Empty or absent _fillables  will not evaluate at all
            _fillable: [ 'userCategory', 'hasBankRelation', 'waysOfAction', 'stepItems', "services", "isLoading",
                'selectedService', 'branches', 'selectedBranch', "userInformation", "selectedChannel", "availability",
                'selectedAvailabilitySlot', 'book', "comfirmationModalVisibility", "appointmentInfo", "appointmentInfoModalVisibility",
                "cancelAppointment", "cancelModalVisibility","appointmentByBranch"
            ],
            // Fields in _guarded will not be writeable
            _guarded: [],
            // Fields in _hidden will not be included in the "values" (values returns an object with this instance's data fields) result
            _hidden: [],
            // Fields in _casts will be type casted before returned. e.g. in the example below id property will be returned as string regardless
            _casts: {
                id: String,
                isPaid: Number,
                paidAt: Boolean,
            },
            // Specify custom getters and setters for fields. If a field is not in _overrides, the default getter and setter will be used.
            _overrides: {},
        });
    }

    /** ACTIONS **/
    static fetchServices = async (params = {}) => {
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(
                type,
                () => AppointmentService.getService(params),
                {
                    subtype: type.FETCH_SERVICES,
                }
            )
        );
    };

    static fetchWaysOfAction = async (eventTypeId) => {
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(
                type,
                () => AppointmentService.getWaysOfAction(eventTypeId),
                {
                    subtype: type.FETCH_WAYS_OF_ACTION,
                }
            )
        );
    };

    static fetchBranches = async (serviceId) => {
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(
                type,
                () => AppointmentService.getBranches(serviceId),
                {
                    subtype: type.FETCH_BRANCHES,
                }
            )
        );
    };

    static fetchAvailability = async (serviceId, selectedBranch, startDay, endDay) => {
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(
                type,
                () =>
                    AppointmentService.getAvailability(
                        serviceId,
                        selectedBranch,
                        startDay,
                        endDay,
                    ),
                {
                    subtype: type.FETCH_AVAILABILITY,
                }
            )
        );
    };

    static setUserCategory = (userCategory) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_USER_CATEGORY,
                userCategory
            )
        );
    };

    static setBankRelation = (relation) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_BANK_RELATION,
                relation
            )
        );
    };

    static stepCompleted = (stepCompletedId) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.STEP_COMPLETED,
                stepCompletedId
            )
        );
    };

    static setSelectedService = (selectedService, eventTypeId, selectedServiceName) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_SELECTED_SERVICE,
                {id: selectedService, eventTypeId: eventTypeId, name: selectedServiceName}
            )
        );
    };

    static setSelectedBranch = (selectedBranch, selectedBranchName) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_SELECTED_BRANCH,
                {id: selectedBranch, name: selectedBranchName}
            )
        );
    };

    static setUserInformation = (userInformation) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_USER_INFORMATION,
                userInformation
            )
        );
    };

    static setSelectedChannel = (channelId) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_SELECTED_CHANNEL,
                channelId
            )
        );
    };

    static setSelectedAvailableSlot = (availableSlot) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_SELECTED_AVAILABLE_SLOT,
                availableSlot
            )
        );
    };

    static requestBookAppointment = async (serviceId, data) => {
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(
                type,
                () => AppointmentService.bookAppointment(serviceId, data),
                {
                    subtype: type.BOOK_APPOINTMENT,
                }
            )
        );
    };

    static setComfirmationModalVisibility = (visible) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_COMFIRMATION_MODAL_VISIBILITY,
                visible
            )
        );
    };

    static initializeStepItems = (byBranch) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.INITIALIZE_STEP_ITEMS,
                byBranch
            )
        );
    };

    save = () => {
        this.saveChanges(type);
    };

    static fetchAppointmentInfo = async (bookingCode) => { 
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(
                type,
                () => AppointmentService.getAppointmentInfo(bookingCode),
                {
                    subtype: type.FETCH_APPOINTMENT_INFO,
                }
            )
        );
    };

    static setAppointmentInfoModalVisibility = (visible) => { 
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_APPOINTMENT_INFORMATION_MODAL_VISIBILITY,
                visible
            )
        );
    };

    static requestCancelAppointment = (id) => {
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(
                type,
                () => AppointmentService.cancelAppointment(id),
                {
                    subtype: type.CANCEL_APPOINTMENT,
                }
            )
        );
    }

    static setCancelModalVisibility = (visible) => {   
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_CANCEL_MODAL_VISIBILITY,
                visible
            )
        );
    }

    static activateFirstStep = () => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.ACTIVATE_FIRST_STEP
            )
        );
    }

    static resetState = () => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.RESET_STATE
            )
        );
    }

    static visibilityForSelectedSlotData = (visible) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.SET_SELECTED_SLOT_DATA_VISIBILITY,
                visible
            )
        );
    }

    static initializeEditAppointment = (appointmentInfoData) => {
        return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.INITIALIZE_EDIT_APPOINTMENT,
                appointmentInfoData
            )
        );
    }

    static editAppointment = (id, data) => {
        return this.dispatchAsync(
            dispatchHelper.dispatchRequest(
                type,
                () => AppointmentService.editAppointment(id, data),
                {
                    subtype: type.EDIT_APPOINTMENT,
                }
            )
        )
    }

    static editAgain = () => {
         return this.dispatch(
            dispatchHelper.dispatchCustomAction(
                type.EDIT_AGAIN
            )
        );
    }

    /** SELECTORS **/
    static getAppointmentState = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState;
        }
    );

    static getUserCategory = createSelector(appointment, (appointmentState) => {
        return appointmentState?.userCategory;
    });

    static getAppointmentLoading = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.isLoading;
        }
    );

    static getBankRelation = createSelector(appointment, (appointmentState) => {
        return appointmentState?.hasBankRelation;
    });

    static getStepItems = createSelector(appointment, (appointmentState) => {
        return appointmentState?.stepItems;
    });

    static getServicesData = createSelector(appointment, (appointmentState) => {
        return appointmentState?.services?.data;
    });

    static getServicesLoading = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.services?.isLoading;
        }
    );

    static getServicesError = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.services?.error;
        }
    );

    static getSelectedService = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.selectedService;
        }
    );

    static getEditAppointmentServiceName = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.appointmentInfo?.data?.serviceName;
        }   
    );

    static getEditAppointmentBranchName = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.appointmentInfo?.data?.branchName;
        }   
    );

    static getIsEditAppointmentMode = createSelector(
        appointment,
        (appointmentState) => {
            return !!appointmentState?.editAppointmentMode || false;
        }
    )

    static getBranchesData = createSelector(appointment, (appointmentState) => {
        return appointmentState?.branches?.data;
    });

    static getBranchesLoading = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.branches?.isLoading;
        }
    );

    static getBranchesError = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.branches?.error;
        }
    );

    static getSelectedBranch = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.selectedBranch;
        }
    );

    static getUserInformation = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.userInformation;
        }
    );

    static getSelectedChannel = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.selectedChannel;
        }
    );

    static getWaysOfAction = createSelector(appointment, (appointmentState) => {
        return appointmentState?.waysOfAction;
    });

    static getWaysOfActionData = createSelector(appointment, (appointmentState) => {
        return appointmentState?.waysOfAction?.data;
    });

    static getWaysOfActionLoading = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.waysOfAction?.isLoading;
        }
    );

    static getWaysOfActionError = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.waysOfAction?.error;
        }
    );

    static getAvailability = createSelector(appointment, (appointmentState) => {
        return appointmentState?.availability;
    });

    static getAvailabilityData = createSelector(appointment, (appointmentState) => {
        return appointmentState?.availability?.data;
    });

    static getAvailabilityLoading = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.availability?.isLoading;
        }
    );

    static getAvailabilityError = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.availability?.error?.message || appointmentState?.availability?.error;
        }
    );

    static getSelectedAvailableSlot = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.selectedAvailableSlot;
        }
    );

    static getBook = createSelector(appointment, (appointmentState) => {
        return appointmentState?.book;
    });

    static getAppointmentIsLoading = createSelector( appointment, (appointmentState) => {
        return appointmentState?.book?.isLoading;
    });

    static getAppointmentError = createSelector(appointment, (appointmentState) => {    
        return appointmentState?.book?.error?.message || appointmentState?.book?.error;  
    });

    static getBookCode = createSelector(appointment, (appointmentState) => {
        return appointmentState?.book?.data?.eventNumber || "";
    });

    static getComfirmationModalVisibility = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.comfirmationModalVisibility || false;
        }
    );

    static getAppointmentInfoData = createSelector(appointment, (appointmentState) => {
        return appointmentState?.appointmentInfo?.data;
    });

    static getAppointmentInfoLoading = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.appointmentInfo?.isLoading;
        }
    );

    static getAppointmentInfoError = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.appointmentInfo?.error?.message || appointmentState?.appointmentInfo?.error;
        }
    );

    static getAppointmentEditData = createSelector(appointment, (appointmentState) => {
        return appointmentState?.appointmentEdit?.data;
    });

    static getAppointmentEditLoading = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.appointmentEdit?.isLoading;
        }
    );

    static getAppointmentEditError = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.appointmentEdit?.error?.message || appointmentState?.appointmentEdit?.error;
        }
    );

    static getAppointmentInfoModalVisibility = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.appointmentInfoModalVisibility || false;
        }
    );

    static getCancelAppointmentData = createSelector(appointment, (appointmentState) => {
        return appointmentState?.cancelAppointment?.data;
    });

    static getCancelAppointmentLoading = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.cancelAppointment?.isLoading;
        }
    );

    static getCancelAppointmentError = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.cancelAppointment?.error?.message || appointmentState?.cancelAppointment?.error;
        }
    );

    static getCancelAppointmentModalVisibility = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.cancelModalVisibility || false;
        }
    );

    static getAppointmentRedirectRoutes = createSelector(
        (state) => state.appointment.stepItems,
        (stepItems) => {
            return stepItems?.map((item) => {
                if (item.state === stepState.DEFAULT || item.state === stepState.ERROR) {
                    return item.path;
                }
                return null;
            }) || [];
        }
    );
    
    static getIsAppointmentInitialized = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.initialized;
        }
    );

    static getIsAppointmentByBranch = createSelector(appointment,
        (appointmentState) => {
            return appointmentState?.isAppointmentByBranch;
        }
    );

    static getSelectedServiceName = createSelector(appointment,
        (appointmentState) => {
            return appointmentState?.selectedServiceName;
        }
    );

    static getSelectedBranchName = createSelector(appointment,
        (appointmentState) => {
            return appointmentState?.selectedBranchName;
        }
    );
    
    static getSelectedSlotDataVisibility = createSelector(appointment, (appointmentState) => {
        return appointmentState?.selectedSlotDataVisibility || false;
    });

    static getSelectedDateAndTime = createSelector([this.getSelectedAvailableSlot, this.getSelectedSlotDataVisibility], (dateAndTime, visibleData) => {
        if (dateAndTime && visibleData) { 
            return {
                date: new Date(dateAndTime.start).toLocaleDateString( { timeZone: 'UTC' }),
                time: new Date(dateAndTime.start).toLocaleTimeString('en-GB', { timeZone: 'UTC', hour: '2-digit', minute: '2-digit' }),
            };

        } else {
            return {
                date: undefined,
                time: undefined,
            };
        }
    });

    static getAppointmentDataChanged = createSelector([this.getAppointmentInfoData, this.getSelectedService, this.getSelectedBranch, this.getSelectedAvailableSlot],
        (appointmentInfoData, selectedService, selectedBranch, selectedDateAndTime) => {
            if (!appointmentInfoData) {
                return false
            } else {
                return (appointmentInfoData?.serviceId === selectedService && appointmentInfoData?.branchId === selectedBranch && appointmentInfoData?.startDate === selectedDateAndTime?.start)
            }
        }
    );

    static getSelectedEventTypeId = createSelector(
        appointment,
        (appointmentState) => {
            return appointmentState?.selectedEventTypeId;
        }
    );
}

export default Appointment;