import { css } from "aphrodite";
import { CustomAppBar } from "./components/CustomAppBar";
import styles from "./styles";

const Layout = ({ children, webView = true }) => {
    return (
        <div className={css(styles.container)}>
            <CustomAppBar webView={webView} />
            {children}
        </div>
    );
};

export default Layout;
