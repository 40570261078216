import { css } from "aphrodite";
import styles from "./styles";
import { Box, Button, Fade, Typography } from "@mui/material";
import theme from "../../../common/theme/mellon";
import infoButtonIcon from "../../../assets/icons/infoButtonIcon.svg";

const CustomMobileServiceButton = ({
    isSelected,
    extraValue,
    value,
    extraValueVisible = false,
    onClickButton = () => {},
    onClickInfo = () => {}
}) => {

    
    return (
        <div className={css(styles.container)}>
            <Box
                sx={{
                    background: isSelected
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main,
                }}
                className={css(styles.buttonDiv)}
            >
                <Button className={css(styles.valueButton)} color="inherit" onClick={onClickButton}>
                    <Typography color="white" varinat="body1">
                        {value}
                    </Typography>
                </Button>
                <Button
                    onClick={onClickInfo}
                    className={css([
                        styles.infoButton,
                        isSelected ? styles.secondary : styles.primary,
                    ])}
                >
                    <img alt="" src={infoButtonIcon} />
                </Button>
            </Box>
            <Fade in={extraValueVisible} timeout={300}>
                <div className={css([styles.info, !extraValueVisible && styles.hideDIv])}>
                    <Typography variant="body2" color="inherit">
                        {extraValue}
                    </Typography>
                </div>
            </Fade>
        </div>
    );
};

export default CustomMobileServiceButton;
