import { Request, formatDate } from "../../Utils";
import Logger from "../../Utils/Logger";

const getService = async (params = {}) => {
    const host = window.constants.REACT_APP_API_URL;
    const result = await Request.get(host + `/service/`, params);
    return result;
};

const getBranches = async (serviceId) => {
    const host = window.constants.REACT_APP_API_URL;
    const result = await Request.get(
        host + (serviceId ? `/service/${serviceId}/branches`: `/branch`),
        {}
    );
    Logger.log(result, "getBranches result");
    return result;
};

const getWaysOfAction = async (eventTypeId) => {
    const host = window.constants.REACT_APP_API_URL;
    const params = {};
    const result = await Request.get(
        host + `/eventtype/${eventTypeId}`,
        params
    );
    Logger.log(result, "getWaysOfAction result");
    return result;
};

const getAvailability = async (serviceId, selectedBranch, startDay, endDay) => {
    const host = window.constants.REACT_APP_API_URL;
    const params = {
        body: {
            start: startDay,
            end: endDay,
            Branchid: selectedBranch,
        },
    };
    const result = await Request.get(
        host + `/service/${serviceId}/availability`,
        params
    );
    Logger.log(result, "getWaysOfAction result");
    return result;
};

const bookAppointment = async (serviceId, data) => {
    const host = window.constants.REACT_APP_API_URL;
    const params = {
        body: data,
    };
    const result = await Request.post(
        host + `/service/${serviceId}/book`,
        params
    );
    Logger.log(result, "bookAppointment result");              
    return result;
};

const getAppointmentInfo = async (bookingCode) => {
    //Fetching the appointment ids
    const host = window.constants.REACT_APP_API_URL;
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        const eventUrl = `${host}/Event?eventNumber=${bookingCode}`;
        const eventResult = await Request.get(eventUrl, { headers });
        Logger.log(eventResult, "get appointment info result");

        if (!eventResult || !eventResult.data || eventResult.data.length === 0) {
            throw new Error('No data returned for event');
        }

        //Fetch the data to display with the ids
        const { branchId, guests, services, start: date, channelId, id, end, users } = eventResult.data[0];
        const guestId = guests[0];
        const serviceId = services[0];

        const urls = [
            `${host}/Branch?Id=${branchId}`,
            `${host}/Guest?Id=${guestId}`,
            `${host}/Service?Id=${serviceId}`,
        ];

        const requests = urls.map(url =>
            Request.get(url, { headers }).catch(err => {
                console.error(`Error fetching data from ${url}:`, err);
                return null;
            })
        );
        const [branchResult, guestResult, serviceResult] = await Promise.all(requests);

        return {
            id: id,
            channelId,
            dateAndTime:  date ? formatDate(date) : null,
            serviceName: serviceResult?.data[0]?.name,
            serviceId: serviceResult?.data[0]?.id,
            eventTypeId: serviceResult.data[0]?.eventTypeId,
            branchName: branchResult?.data[0]?.name,
            branchId: branchResult?.data[0]?.id,
            startDate: date,
            endDate: end,
            address: branchResult?.data[0]?.address,
            name: guestResult?.data[0]
                ? `${guestResult.data[0].firstName} ${guestResult.data[0].lastName}`
                : null,
            phone: guestResult?.data[0]?.phoneNumber,
            email: guestResult?.data[0]?.email,
            guests: guests,
            users: users
        };
    } catch (error) {
        console.error('Error fetching appointment info:', error.message || error);
        return {
            status: "error",
            message: error.message || "An unknown error occurred"
        };
    }
};

const validateAppointment = async (bookingCode) => {
    const host = window.constants.REACT_APP_API_URL;
    const params = {
        body: {
            EventNumber: bookingCode,
            PhoneNumber: "+306986855787",
        },
    };
    const result = await Request.get(host + `/Event/validate`, params);
    Logger.log(result, "validate appointment result");
    return result;
};

const cancelAppointment = async (id) => {
    const host = window.constants.REACT_APP_API_URL;
    const params = {};
    const result = await Request.post(host + `/Event/${id}/cancel`, params);
    Logger.log(result, "cancel appointment result");
    return result;
};

const editAppointment = async (serviceId, data) => {
    const host = window.constants.REACT_APP_API_URL;
    const params = {
        body: data,
    };
    const result = await Request.put(
        host + `/Event/${serviceId}`,
        params
    );
    Logger.log(result, "edit appointment result");              
    return result;
};

export const AppointmentService = {
    cancelAppointment,
    validateAppointment,
    getAppointmentInfo,
    getService,
    getBranches,
    getWaysOfAction,
    getAvailability,
    bookAppointment,
    editAppointment
};
