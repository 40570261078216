import { Box, Typography } from "@mui/material";
import { css } from "aphrodite";
import styles from "./styles";
import StepDataInfo from "../StepDataInfo/StepDataInfo";


const CommonLayoutTitle = ({ 
    statusTitle,
    statusSubtitle,
    title,
    iconSrc,
    description,
    stepDataLayoutVisible = false,
 }) => {

    return (
        <div className={css(styles.container)}>
        {/* Steps or Status */}
            <div className={css(styles.stepsContainer)}>
                    <Typography variant="h5" color="primary">
                        {statusTitle}
                    </Typography>
                    {
                        statusSubtitle && (
                            <Typography variant="h5" color="secondary">
                                {" "}
                                &nbsp;/&nbsp;
                                {" "}
                                {statusSubtitle}
                            </Typography>
                        )
                    }
            </div>
            {stepDataLayoutVisible ? (<StepDataInfo />) : (<Box sx={{margin: "30px 0px"}}/>)}
            <div className={css(styles.titleRow)}>
                <img alt="" src={iconSrc} />
                <Typography variant="h4">{title}</Typography>
            </div>
            {
                description && (
                    <Typography
                        variant="body1"
                        color="inherit"
                        sx={{ marginTop: "26px" }}
                    >
                        {description}
                    </Typography>
                )
            }
    </div>
    );
}   

export default CommonLayoutTitle;   