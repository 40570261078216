import { css } from "aphrodite";
import styles from "./styles";
import { Button, Typography } from "@mui/material";
import { BranchMap } from "../BranchMap";
import { useTranslation } from "react-i18next";
import { AnimatedButton } from "../AnimatedButton";

const SelectBranchComponent = ({
    branches,
    webView = true,
    selectedBranchId,
    onBranchSelect = (id, name) => {},
}) => {
    const [t] = useTranslation(["translation"]);

    if (!branches || branches.length === 0 || !branches[0]?.id) {
        return (
            <div className={css(styles.centerEmptyContainer)}>
                <Typography variant="h6">
                    {t("No available branches have been found. Please try again later.")}
                </Typography>
            </div>
        );
    }

    return (
        <>
            <BranchMap
                onMarkerClick={(marker) => {
                    console.log(marker);
                }}
                markers={branches.map((branch) => ({
                    id: branch?.id,
                    lon: branch?.longitude,
                    lat: branch?.latitude,
                    title: branch?.name,
                    description: branch?.address,
                }))}
            />
            <div className={css(styles.branchList)}>
                { 
                    branches.map((branch, index) => (
                        <div className={css(styles.row)} key={index}>
                            <div className={css(styles.branchInfoColumn)}>
                                <Typography variant="h6">{branch?.name}</Typography>
                                <Typography variant="body1">{branch?.address}{branch?.phoneNumber ? (", " + branch.phoneNumber) : ""}{branch?.email ? (", " + branch.email) : ""}</Typography>
                            </div>
                            {
                                webView ? (
                                    <AnimatedButton isSelected={(branch.id === selectedBranchId)} text={t("Schedule Appointment")} onClick={() => {onBranchSelect(branch.id, branch.name)}}/>
                                ) : (
                                    <Button 
                                        disableElevation
                                        disableRipple
                                        variant="contained"
                                        color={(branch.id === selectedBranchId) ? "secondary" : "primary"}
                                        className={css(styles.button)}
                                        onClick={() => {onBranchSelect(branch.id, branch.name)}}
                                    >
                                        <Typography variant="h6" color="white">
                                            {t("Schedule Appointment")}
                                        </Typography>
                                    </Button>
                                )
                            }
                            
                        </div>
                    ))
                }
            </div>
        </>
    )
};

export default SelectBranchComponent;
