import { CommonLayout } from "../../common/components/CommonLayout";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import searchIcon from "../../assets/icons/searchIcon.svg";
import { RequestLayout } from "../../common/components/RequestLayout";
import { Field, Formik } from "formik";
import CustomInput from "../../common/components/CustomInput/CustomInput";
import * as Yup from "yup";
import { CustomMainButton } from "../../common/components/CustomMainButton";
import Appointment from "../../../Models/Appointment/Appointment";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { notify } from "../../../Utils";
import { RoutePath } from "../../../Utils/routePath";
import { useNavigate } from "react-router";
import { CommonLayoutTitle } from "../../common/components/CommonLayoutTitle";
import Media from "../../../Models/Media/Media";
import { MobileTitle } from "../../common/components/MobileTitle";
import { Typography } from "@mui/material";
import { css } from "aphrodite";

const FindYourAppointmentPage = () => {
    const [t] = useTranslation(["translation"]);
    const navigate = useNavigate();
    const appointmentInfoData = useSelector(Appointment.getAppointmentInfoData);
    const appointmentInfoLoading = useSelector(Appointment.getAppointmentInfoLoading);
    const appointmentInfoError = useSelector(Appointment.getAppointmentInfoError);
    const [emailOrNumber, setEmailOrNumber] = useState("");
    const webView = useSelector(Media.getIsWebView);

    useEffect(() => {
        if (appointmentInfoData?.email && !!emailOrNumber) {
            if (emailOrNumber === appointmentInfoData?.email || emailOrNumber === appointmentInfoData?.phoneNumber) {
                Appointment.initializeEditAppointment(appointmentInfoData);
                Appointment.activateFirstStep();
                navigate(RoutePath.selectServicePage);
            } else if ((emailOrNumber !== appointmentInfoData?.email && emailOrNumber !== appointmentInfoData?.phoneNumber)) { 
                notify(t("E-mail or Phone number not found in our system."), "error", 4000);
            }
        }
    }, [appointmentInfoData, emailOrNumber, t, navigate]);

    const handleFindAppointmentButtonClicked = (values) => {
        Appointment.fetchAppointmentInfo(
            values.referenceNumber
        );
        setEmailOrNumber(values.emailOrNumber);
    }

    const validationSchema = Yup.object().shape({
        emailOrNumber: Yup.string()
            .test(
                "email-or-phone",
                t("Must be a valid email or phone number"),
                (value) =>
                    !!value &&
                    (Yup.string().email().isValidSync(value) ||
                        /^[0-9]{10,15}$/.test(value))
            )
            .required(t("Email or phone number is required")),
        referenceNumber: Yup.string()
            .required(t("Reference number is required")),
    });

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        webView ? (
            <CommonLayout>
                <CommonLayoutTitle 
                    statusTitle= {t("Edit Appointment")}
                    statusSubtitle= {t("Find your appointment")}
                    iconSrc={searchIcon}
                    title={t("Find your appointment")}
                    description={t(
                        "Please enter the e-mail or phone you provided when you booked your appointment, as well as the Refference Number to your appointment. Your Reference Number has been sent to the e-mail and/or phone that you provided."
                    )}
                />
                <RequestLayout loading={appointmentInfoLoading} error={appointmentInfoError}>
                    <Formik
                        initialValues={{
                            emailOrNumber: "",
                            referenceNumber: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFindAppointmentButtonClicked}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                            dirty,
                        }) => (
                            <>
                                <Field
                                    label={t("E-mail / Phone")}
                                    type="text"
                                    name="emailOrNumber"
                                    placeholder={t("Email / Phone")}
                                    value={values.emailOrNumber}
                                    verticalMode={false}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    component={CustomInput}
                                />
                                <Field
                                    label={t("Reference Number")}
                                    type="text"
                                    verticalMode={false}
                                    name="referenceNumber"
                                    placeholder={t("Reference Number")}
                                    value={values.referenceNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    component={CustomInput}
                                />
                                <CustomMainButton
                                    isDisabled={!(isValid && dirty)}
                                    fixedSize={false}
                                    value={t("Find my appointment")}
                                    textColor="inherit"
                                    style={[styles.button, !(isValid && dirty) && styles.disableStyle]}
                                    onClick={handleSubmit}
                                />
                            </>
                        )}
                    </Formik>
                </RequestLayout>
            </CommonLayout>
        ) : (
            <CommonLayout>
                <MobileTitle iconSrc={searchIcon} title={t("Find Appointment")} onClickBackButton={handleGoBack}/>
                <Typography variant="body1" >
                    {t("Please enter the e-mail or phone you provided when you booked your appointment, as well as the Refference Number to your appointment. Your Reference Number has been sent to the e-mail and/or phone that you provided.")}
                </Typography>
                <RequestLayout loading={appointmentInfoLoading} error={appointmentInfoError}>
                    <Formik
                        initialValues={{
                            emailOrNumber: "",
                            referenceNumber: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFindAppointmentButtonClicked}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                            dirty,
                        }) => (
                            <div className={css(styles.mobileContainer)}>
                                <Field
                                    label={t("E-mail / Phone")}
                                    type="text"
                                    name="emailOrNumber"
                                    placeholder={t("Email / Phone")}
                                    value={values.emailOrNumber}
                                    verticalMode={false}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    component={CustomInput}
                                    hideErrorMessage={true}
                                />
                                <Field
                                    label={t("Reference Number")}
                                    type="text"
                                    verticalMode={false}
                                    name="referenceNumber"
                                    placeholder={t("Reference Number")}
                                    value={values.referenceNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    component={CustomInput}
                                    hideErrorMessage={true}
                                />
                                <CustomMainButton
                                    isDisabled={!(isValid && dirty)}
                                    fixedSize={false}
                                    value={t("Find my appointment")}
                                    textColor="inherit"
                                    style={[styles.mobileButton, !(isValid && dirty) && styles.disableStyle]}
                                    onClick={handleSubmit}
                                />
                                {errors.emailOrNumber && touched.emailOrNumber && (
                                    <Typography variant="body1" color="error" className={css(styles.errorMessage)}>
                                        {"*" + errors.emailOrNumber}
                                    </Typography>
                                )}
                                {errors.referenceNumber && touched.referenceNumber && (
                                    <Typography variant="body1" color="error" className={css(styles.errorMessage)}>
                                        {"*" + errors.referenceNumber}
                                    </Typography>
                                )}
                            </div>
                        )}
                    </Formik>
                </RequestLayout>
            </CommonLayout>
        )
    );
};

export default FindYourAppointmentPage;
