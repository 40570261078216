import { createType } from '../../../Utils';

const actionName = 'APPOINTMENT';

export const REQUEST = createType.request(`${actionName}`);
export const SUCCESS = createType.success(`${actionName}`);
export const FAILURE = createType.failure(`${actionName}`);
export const SET_USER_CATEGORY = createType.customAction(actionName, 'SET_USER_CATEGORY');
export const SET_BANK_RELATION = createType.customAction(actionName, 'SET_BANK_RELATION');
export const STEP_COMPLETED = createType.customAction(actionName, 'STEP_COMPLETED');
export const SET_SELECTED_SERVICE = createType.customAction(actionName, 'SET_SELECTED_SERVICE');
export const SET_SELECTED_BRANCH = createType.customAction(actionName, 'SET_SELECTED_BRANCH');
export const SET_SELECTED_CHANNEL = createType.customAction(actionName, 'SET_SELECTED_CHANNEL');
export const SET_USER_INFORMATION = createType.customAction(actionName, 'SET_USER_INFORMATION');
export const SET_SELECTED_AVAILABLE_SLOT = createType.customAction(actionName, 'SET_SELECTED_AVAILABLE_SLOT');
export const SET_APPOINTMENT_INFORMATION_MODAL_VISIBILITY = createType.customAction(actionName, 'SET_APPOINTMENT_INFORMATION_MODAL_VISIBILITY');
export const SET_COMFIRMATION_MODAL_VISIBILITY = createType.customAction(actionName, 'SET_COMFIRMATION_MODAL_VISIBILITY');
export const SET_CANCEL_MODAL_VISIBILITY = createType.customAction(actionName, 'SET_CANCEL_MODAL_VISIBILITY');
export const ACTIVATE_FIRST_STEP = createType.customAction(actionName, 'ACTIVATE_FIRST_STEP');
export const RESET_STATE = createType.customAction(actionName, 'RESET_STATE');
export const INITIALIZE_STEP_ITEMS = createType.customAction(actionName, 'INITIALIZE_STEP_ITEMS');
export const SET_SELECTED_SLOT_DATA_VISIBILITY = createType.customAction(actionName, 'SET_SELECTED_SLOT_DATA_VISIBILITY');
export const INITIALIZE_EDIT_APPOINTMENT = createType.customAction(actionName, 'INITIALIZE_EDIT_APPOINTMENT');
export const EDIT_AGAIN = createType.customAction(actionName, 'EDIT_AGAIN');
export const SAVE = createType.customAction(actionName, 'SAVE');
export const FETCH_SERVICES = createType.subtype('FETCH_SERVICES');
export const FETCH_BRANCHES = createType.subtype('FETCH_BRANCHES');
export const BOOK_APPOINTMENT = createType.subtype('BOOK_APPOINTMENT');
export const FETCH_WAYS_OF_ACTION = createType.subtype( 'FETCH_WAYS_OF_ACTION');
export const FETCH_APPOINTMENT_INFO = createType.subtype( 'FETCH_APPOINTMENT_INFO');
export const FETCH_AVAILABILITY = createType.subtype( 'FETCH_AVAILABILITY');
export const CANCEL_APPOINTMENT = createType.subtype( 'CANCEL_APPOINTMENT');
export const EDIT_APPOINTMENT = createType.subtype( 'EDIT_APPOINTMENT');
