import { css } from "aphrodite";
import styles from "./styles";
import { Input, Typography } from "@mui/material";


const CustomInput = ({
    field,
    label,
    name,
    id,
    value,
    verticalMode = true,
    hideErrorMessage = false,
    form: { touched, errors },
    ...props
  }) => {
    return (
      <div className={css([styles.container, (!verticalMode) && styles.rowButton])}>
        <div className={css(
            styles.inputContainer,
            (touched[field.name] && errors[field.name]) && styles.error
            )}>
          <Input
            fullWidth
            disableUnderline={true}
            id={id}
            type="text"
            {...field}
            {...props}
          />
        </div>
        {!hideErrorMessage && 
          (
            <>
              {touched[field.name] && errors[field.name] && (
                  <div className={css(styles.errorField)}>
                    <Typography className={css(styles.error)} variant="subtitle2">
                        {errors[field.name]}
                    </Typography>
                  </div>
              )}
            </>
          )
        }
      </div>
    );
  };
  
export default CustomInput;
