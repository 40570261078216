import { StyleSheet } from "aphrodite";
import theme from "../../../common/theme/mellon";

export default StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        color: theme.palette.primary.dark
    },
    calendarHeader: {
        ":global([role='row'])": {
            backgroundColor: "black",
        },
    },
    datePickerContainer: {
        display: "flex",
        flexDirection: "column",
        border: "2px solid" + theme.palette.primary.main,
        background: theme.colors.white,
    },
    roundBorder: {
        borderRadius: "5px",
    },
    buttonStyle: {
        marginLeft: "20px",
        marginRight: "20px",
        marginBottom: "20px",
        alignSelf: "flex-start"
    },
    alignEnd: {
        alignSelf: "flex-end"
    }
});
