import { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { SetViewToBounds } from "./BranchMapHelper";
import styles from "./styles";
import { css } from "aphrodite";
import { Icon } from "leaflet";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const BranchMap = ({ markers, onMarkerClick, MarkerButtonVisible}) => {
    const [t] = useTranslation(["translation"]);
    const [mapPosition] = useState([markers[0]?.lat || 0, markers[0]?.lon || 0]);
    const [zoomLevel] = useState(13);
    const customIcon = new Icon({iconUrl: require("../../../assets/icons/markerRedIcon.png"),iconSize: [35, 35]});

    return (
        <>
            <MapContainer
                zoom={zoomLevel}
                center={mapPosition}
                attributionControl={false}
                scrollWheelZoom={false}
                style={{ height: "400px", width: "100%" }}
                className={css(styles.datePickerContainer)}
            >
                <TileLayer
                    attribution=""
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markers.map((marker, idx) => (
                    <Marker
                        key={idx}
                        position={[marker.lat, marker.lon]}
                        eventHandlers={{
                            click: () => {},
                        }}
                        icon={customIcon}
                    >
                        <Popup>
                            <div className={css(styles.popup)}>
                                <Typography
                                    variant="subtitle1"
                                    className={css(styles.title)}
                                >
                                    {marker.title}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    className={css(styles.description)}
                                >
                                    {marker.description}
                                </Typography>
                                {MarkerButtonVisible && <Button
                                    onClick={() => {
                                        onMarkerClick(marker);
                                    }}
                                    className={css(styles.popupButton)}
                                >
                                    <Typography variant="subtitle1">
                                        {t("Select")}
                                    </Typography>
                                </Button>}
                            </div>
                        </Popup>
                    </Marker>
                ))}
                <SetViewToBounds positions={markers} />
            </MapContainer>
        </>
    );
};

export default BranchMap;
