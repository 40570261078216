import { StyleSheet } from "aphrodite";


export default StyleSheet.create({
    centerContainer: {
        margin: "auto",
      },
      smallPadding: {
        padding: "20px"
      },
      text: {
        textAlign: "center",
      },
      backDrop: {
        position: "absolute",
        color: "#fff",
        zIndex: 0,
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
    },
});