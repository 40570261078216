import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
  button: {
    padding: "14px 20px",
    borderRadius: "0px"
  },
  fixedSize: {
    width: "400px",
    height: "105px",
    '@media (max-width: 799px)' : {
      width: 374,
      height: 60
    }
  },
});