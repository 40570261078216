import { Button, Typography } from "@mui/material";
import { css } from "aphrodite";
import styles from "./styles";

const SlotButton = ({
    value = "",
    textVariant = "body1",
    isSelected = false,
    isDisabled = false,
    onClick = () => {},
}) => {
    return (
        <Button
            disableElevation
            variant={isSelected ? "contained" : "text"}
            color={isSelected ? "secondary" : "primary"}
            className={css([styles.button])}
            disabled={isDisabled}
            onClick={onClick}
        >
            <Typography variant={textVariant} color={isDisabled ? "disabled" : isSelected ? "white" : "primay"}>
                {" "}
                {value}{" "}
            </Typography>
        </Button>
    );
};

export default SlotButton;