import { css } from "aphrodite";
import styles from "./styles";
import { useSelector } from "react-redux";
import Appointment from "../../../../Models/Appointment/Appointment";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Media } from "../../../../Models";

const StepDataInfo = () => {
    const serviceName = useSelector(Appointment.getSelectedServiceName);
    const branchName = useSelector(Appointment.getSelectedBranchName);
    const dateAndTime = useSelector(Appointment.getSelectedDateAndTime);
    const webView = useSelector(Media.getIsWebView);
    const [t] = useTranslation(["translation"]); 

    return (
        <div className={css(styles.container, (!serviceName && !branchName && !dateAndTime?.date) && styles.marginEmptyData)}>
            {serviceName && (
                <div className={css(styles.rowContainer)}>
                    <Typography variant="body1" color="inherit">{t("Service: ")}</Typography>
                    <Typography variant="body1" color="secondary" className={css(styles.textValue)}>&nbsp;{serviceName}</Typography>
                </div>
            )}
            {branchName && (
                <div className={css(styles.rowContainer)}>
                    {(serviceName && webView) && <Typography variant="body1" color="primary">,&nbsp;</Typography>}
                    <Typography variant="body1" color="inherit">{t("Branch: ")}</Typography>
                    <Typography variant="body1" color="secondary"  className={css(styles.textValue)}>&nbsp;{branchName}</Typography>
                </div>
            )}
            {dateAndTime?.date && (
                <div className={css(styles.rowContainer)}>
                    {webView && <Typography variant="body1" color="primary">,&nbsp;</Typography>}
                    <Typography variant="body1" color="inherit">{t("Date: ")}</Typography>
                    <Typography variant="body1" color="secondary"  className={css(styles.textValue)} >&nbsp;{dateAndTime.date}</Typography>
                </div>
            )}
            {dateAndTime?.time && (
                <div className={css(styles.rowContainer)}>
                    {webView && <Typography variant="body1" color="primary">,&nbsp;</Typography>}
                    <Typography variant="body1" color="inherit">{t("Time: ")}</Typography>
                    <Typography variant="body1" color="secondary"  className={css(styles.textValue)} >&nbsp;{dateAndTime.time}</Typography>
                </div>
            )}
        </div>
    );
};

export default StepDataInfo;
