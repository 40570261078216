import React, { useState } from "react";
import { StaticDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { css } from "aphrodite";
import styles from "./styles";
import { Typography } from "@mui/material";
import "./style.css"

const CustomStaticDatePicker = ({
    onDateChange,
    title = "Select Date",
    minDate = new Date(),
    activeDates = 14,
}) => {
    const [value, setValue] = useState(new Date());

    const handleDateChange = (newValue) => {
        setValue(newValue);
        if (onDateChange) {
            onDateChange(newValue);
        }
    };

    return (
        <div className={css(styles.container)}>
            {title && <Typography variant="h6" color="inherit">{title}</Typography>}
            <div
                className={css(
                    styles.datePickerContainer,
                )}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo="day"
                        value={value}
                        onChange={handleDateChange}
                        minDate={minDate}
                        maxDate={
                            new Date(
                                minDate.getFullYear(),
                                minDate.getMonth(),
                                minDate.getDate() + activeDates
                            )
                        }
                        textField={(params) => <input {...params} />}
                        renderDay={(day, selectedDates, pickersDayProps) => {
                            return (
                                <div {...pickersDayProps}>{day.getDate()}</div>
                            );
                        }}
                        components={{
                            ActionBar: () => null,
                            OpenPickerIcon: () => null,
                            Toolbar: () => null,
                        }} 
                    />
                </LocalizationProvider>
            </div>
        </div>
    );
};

export default CustomStaticDatePicker;
