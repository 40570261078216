import {StyleSheet} from 'aphrodite';
import theme from '../../../common/theme/mellon';

export default StyleSheet.create({
    container: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        background: theme.palette.primary.main,
        padding: "2px 12px 2px 12px"
    },
    mobileAppbar: {
        width: "100%",
        display: "flex",
        background: theme.palette.primary.main,
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        padding: "10px",
        height: "105px",
    },
    row: {
        alignSelf: "flex-end",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "0px 25px 15px 0px"
    },
    rowMobile :{ 
        alignSelf: "flex-end",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "0px 13px 0px 0px"
    },
    button: {
        padding: 0,
        minWidth: "auto",
    },
    divider: {
        height: 18,
        border: `1px solid ${theme.palette.secondary.main}`,
        margin: "0px 5px"
,    }
});
