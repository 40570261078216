import { css } from "aphrodite";
import { CommonLayout } from "../../common/components/CommonLayout";
import styles from "./styles";
import { useTranslation } from "react-i18next";
import cancelAppointmentCompletedIcon from "../../assets/icons/cancelAppointmentCompletedIcon.svg";
import { CommonLayoutTitle } from "../../common/components/CommonLayoutTitle";
import { CustomMainButton } from "../../common/components/CustomMainButton";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../Utils/routePath";
import { useSelector } from "react-redux";
import { Media } from "../../../Models";
import { CompleteCommonMobileLayout } from "../../common/components/CompleteCommonMobileLayout";

const CancelAppointmentCompletedPage = () => {
    const [t] = useTranslation(["translation"]);
    const navigate = useNavigate();
    const webView = useSelector(Media.getIsWebView);

    const handleGoHome = () => {
        navigate(RoutePath.appointmentPage);
    };

    return (
        <CommonLayout>
            {webView ? (
                <>
                    <CommonLayoutTitle
                        statusTitle={t("Done!")}
                        iconSrc={cancelAppointmentCompletedIcon}
                        title={t("Your Appointment has been cancelled")}
                        description={t(
                            "Feel free to use our system again to make a new appointment when you desire."
                        )}
                        stepDataLayoutVisible={true}
                    />
                    <div className={css(styles.container)}>
                        <CustomMainButton
                            color="primary"
                            style={styles.button}
                            value={t("Back to Home")}
                            onClick={handleGoHome}
                        />
                    </div>
                </>
            ) : (
                <CompleteCommonMobileLayout
                    title={t("Done!")}
                    description={t(
                        "Your Appointment has been cancelled. Feel free to use our system again to make a new appointment when you desire."
                    )}
                    appointmentValuesVisible={true}
                    handleGoHome={handleGoHome}
                />
            )}
        </CommonLayout>
    );
};

export default CancelAppointmentCompletedPage;
