import { RoutePath } from "./routePath";
import selectServiceIcon from "../View/assets/icons/selectServiceIcon.svg";
import selectBranchIcon from "../View/assets/icons/selectBranchIcon.svg";
import scheduleAppointmentIcon from "../View/assets/icons/scheduleAppointmentIcon.svg"
import verifyContactDetailsIcon from "../View/assets/icons/verifyContactDetailsIcon.svg";


const stepState = {
    COMPLETE: "COMPLETE",
    ERROR: "ERROR",
    DEFAULT: "DEFAULT",
    ACTIVE: "ACTIVE",
};

const stepLayoutItemsId = {
    selectService: "selectService",
    selectBranch: "selectBranch",
    selectDateAndTime: "selectDateAndTime",    
    verifyContactDetails: "verifyContactDetails",
}

//Steps for appointment by Service
const stepLayoutItems = [
    {
        id: stepLayoutItemsId.selectService,
        value: "Select Service",
        mobileValue: "Select Service",
        icon: selectServiceIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.selectServicePage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: RoutePath.selectBranchPage
    },
    {
        id: stepLayoutItemsId.selectBranch,
        value: "Select Branch",
        mobileValue: "Select Branch",
        icon: selectBranchIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.selectBranchPage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: RoutePath.selectDateAndTimePage
    },
    {
        id:  stepLayoutItemsId.selectDateAndTime,
        value: "Schedule Appointment",
        mobileValue: "Schedule Appointment",
        icon: scheduleAppointmentIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.selectDateAndTimePage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: RoutePath.verifyContactDetailsPage
    },
    {
        id: stepLayoutItemsId.verifyContactDetails,
        value: "Verify Contact Details",
        mobileValue: "Verify Details",
        icon: verifyContactDetailsIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.verifyContactDetailsPage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: ""
    },
];

//Steps for appointment by branch
const stepLayoutItemsByBranch = [
    {
        id: stepLayoutItemsId.selectBranch,
        value: "Select Branch",
        mobileValue: "Select Branch",
        icon: selectBranchIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.selectBranchPage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: RoutePath.selectServicePage
    },
    {
        id: stepLayoutItemsId.selectService,
        value: "Select Service",
        mobileValue: "Select Service",
        icon: selectServiceIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.selectServicePage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: RoutePath.selectDateAndTimePage
    },
    {
        id:  stepLayoutItemsId.selectDateAndTime,
        value: "Schedule Appointment",
        mobileValue: "Schedule Appointment",
        icon: scheduleAppointmentIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.selectDateAndTimePage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: RoutePath.verifyContactDetailsPage
    },
    {
        id: stepLayoutItemsId.verifyContactDetails,
        value: "Verify Contact Details",
        mobileValue: "Verify Details",
        icon: verifyContactDetailsIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.verifyContactDetailsPage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: ""
    },
];

//Steps for appointment by branch
const stepLayoutItemsEditAppointment =  [
    {
        id: stepLayoutItemsId.selectService,
        value: "Select Service",
        mobileValue: "Select Service",
        icon: selectServiceIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.selectServicePage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: RoutePath.selectBranchPage,
        editMode: true
    },
    {
        id: stepLayoutItemsId.selectBranch,
        value: "Select Branch",
        mobileValue: "Select Branch",
        icon: selectBranchIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.selectBranchPage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: RoutePath.selectDateAndTimePage,
        editMode: true
    },
    {
        id:  stepLayoutItemsId.selectDateAndTime,
        value: "Schedule Appointment",
        valmobileValue: "Schedule Appointment",
        icon: scheduleAppointmentIcon,
        state: stepState.DEFAULT,
        visible: true,
        path: RoutePath.selectDateAndTimePage,
        label: null,
        homePath: RoutePath.appointmentPage,
        nextStepPath: RoutePath.verifyContactDetailsPage,
        editMode: true
    },
];


const description = {
    "Αγορά χρυσών λιρών Αγγλίας": "Ραντεβού για να αγοράσετε χρυσές λίρες Αγγλίας (πληροφ. Link TtE)",
    "Πώληση χρυσών λιρών": "Ραντεβού για να πωλήσετε χρυσές λίρες Αγγλίας & χρυσά νομίσματα (πληροφ. Link TtE)",
    "Πώληση ράβδων χρυσού": "Ραντεβού για να πωλήσετε ράβδους χρυσού (πληροφ. & προϋποθέσεις Link TtE)",
    "Αγορά ξένων τραπεζογραμματίων": "Ραντεβού για να αγοράσετε ξένα τραπεζογραμμάτια",
    "Πώληση ξένων τραπεζογραμματίων": "Ραντεβού για να πωλήσετε ξένα τραπεζογραμμάτια",
    "Προμήθεια κερμάτων ευρώ": "Ραντεβού για να προμηθευτείτε κέρματα ευρώ (πληροφ. Link TtE)",
    "Ανταλλαγή κερμάτων με τραπεζογραμμάτια": "Ραντεβού για να ανταλλάξετε κέρματα ευρώ με τραπεζογραμμάτια (πληροφ. Link TtE)",
    "Ανταλλαγή φθαρμένων τραπεζογραμματίων": "Ραντεβού για να ανταλλάξετε φθαρμένα τραπεζογραμμάτια ευρώ (όροι ανταλλαγής link TtE)",
    "Ανταλλαγή τραπεζογραμματίων σε άλλα μικρότερης αξίας": "Ραντεβού για να ανταλλάξετε τραπεζογραμμάτια ευρώ έναντι άλλων μικρότερης αξίας",
    "Αναμνηστικά - συλλεκτικά προϊόντα": "Ραντεβού για να προμηθευτείτε αναμνηστικά -συλλεκτικά νομισματικά προϊόντα",
    "Αναμνηστικά - συλλεκτικά προϊόντα (για προεγγραμμένους)": "Ραντεβού για να προμηθευτείτε αναμνηστικά -συλλεκτικά νομισματικά προϊόντα (για προεγγραμμένους) κατόπιν προεγγραφής στα Υποκαταστήματα της ΤτΕ",
    "Εξόφληση επιταγής": "Ραντεβού για να εξοφλήσετε επιταγή",
    "Κατάθεση μετρητών / επιταγής σε λογαριασμό δημοσίου": "Ραντεβού για να καταθέσετε μετρητά ή επιταγή σε λογαριασμούς δημοσίου",
    "Δημόσιοι υπόλογοι / διαχειριστές ξένων τραπεζογραμματίων": "Ραντεβού για δημόσιους υπολόγους /διαχειριστές ξένων τραπεζογραμματίων",
    "Είσπραξη προκαταβολών ΠΔΕ": "Ραντεβού για να εισπράξετε προκαταβολές ΠΔΕ",
    "Είσπραξη ποσών μισθοδοσίας υπαλλήλων εξωτερικού": "Ραντεβού για να εισπράξετε ποσά που αφορούν μισθοδοσία υπαλλήλων σε αρχές εξωτερικού"
};


export {
    stepLayoutItemsId,
    stepState,
    stepLayoutItems,
    stepLayoutItemsByBranch,
    description,
    stepLayoutItemsEditAppointment
};
